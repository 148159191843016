import React, { FunctionComponent, useContext } from "react";
import configsJson from "../../../configs.json";
import GA from "../../../GA/ga";
import { Configs } from "../../../Types/types";
import { Context, ExplorerState } from "../../Explorer/explorer";
import "./home.scss";

const Home: FunctionComponent = () => {
  const [state, setState]: [ExplorerState, CallableFunction] =
    useContext(Context);

  if (state.hiddenInterface || state.autoplay) {
    return <></>;
  }

  const configs: Configs = configsJson;

  const returnToLandingPage = () => {
    setState({ ...state, landingPage: true, autoplay: false });

    const langPrefix = state.language.languageShort.toUpperCase();
    GA.sendEvent(`${langPrefix}_Click_Home`, {});
  };

  return (
    <div
      className={
        state.type === "promo" ? "preceram-home promo" : "preceram-home"
      }
      onClick={() => {
        returnToLandingPage();
      }}
    >
      <img
        src={
          state.type === "promo"
            ? state.language[configs.logo]
            : configs.logoIcon
        }
        alt=""
      ></img>
    </div>
  );
};

export default Home;
