import React, { FunctionComponent } from "react";
import { MarzipanoAdvancedAutoplaySettings } from "../../Types/marzipano.types";
import { Guideline } from "../../Types/types";
import AdvancedAudio from "./advancedAudio";

interface AdvancedAutoplayProps {
  settings: MarzipanoAdvancedAutoplaySettings;
}

const AdvancedAutoplay: FunctionComponent<AdvancedAutoplayProps> = ({
  settings,
}) => {
  const autoplayGuideline: Guideline[] = require(`../../Data/${settings.guidelines}`);

  const media = autoplayGuideline.map((g, idx) => {
    return g.media?.type === "audio" ? (
      <AdvancedAudio
        key={`audio-aa-${idx}`}
        guide={autoplayGuideline}
        selfIdx={idx}
      />
    ) : (
      <div key={`non-audio-aa-${idx}`}></div>
    );
  });

  if (!settings.enabled) return <></>;

  return (
    <>
      <div className="advanced-autoplay-block-focus"></div>
      {media}
    </>
  );
};

export default AdvancedAutoplay;
