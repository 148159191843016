import React, { FunctionComponent, useContext, useState } from "react";
import { ReactComponent as CloseSVG } from "../../../../Icons/UI/preceram/close.svg";
import { ReactComponent as CollectionsSVG } from "../../../../Icons/UI/preceram/collections.svg";
import { ReactComponent as ArticleSVG } from "../../../../Icons/UI/preceram/article.svg";
import { Context, ExplorerState } from "../../../Explorer/explorer";
import "./menu.scss";
import promoPagesJson from "../../../../Data/promo_pages.json";
import { PromoPage, TranslationObject } from "../../../../Types/types";
import GA from "../../../../GA/ga";

const Menu: FunctionComponent = () => {
  const promoPages: PromoPage[] = promoPagesJson;

  const [state, setState]: [ExplorerState, CallableFunction] =
    useContext(Context);

  const [menuOpen, toggleMenu] = useState<boolean>(false);
  const [selectedSubmenu, selectSubmenu] = useState<number>(0);

  let currentSceneIndex = state.data.scenes.findIndex(
    (scene) => scene.id === state.spaceId
  );
  let nScenes = state.data.scenes.length;

  if (state.data.settings.navBar.panoSequence.length > 0) {
    currentSceneIndex = state.data.settings.navBar.panoSequence.findIndex(
      (p) => p.pano === state.spaceId
    );
    nScenes = state.data.settings.navBar.panoSequence.length;
  }

  let header = state.data.scenes.find((s) => s.id === state.spaceId)?.name;
  if (state.type === "promo") {
    header = promoPages[parseInt(state.spaceId)].name;
  }
  header = header ? state.language[header] : header;

  const menuClick = (open: boolean) => {
    toggleMenu(open);
  };

  const menuOpenClass = menuOpen && !state.autoplay ? " open" : "";

  const selectPanorama = (id: string) => {
    toggleMenu(false);
    setState({ ...state, spaceId: id, type: "360" });
  };

  const translations: TranslationObject = require(`../../../../Language/${state.language.languageShort}.json`);

  let content = <></>;

  switch (selectedSubmenu) {
    case 0:
      const panoramas = state.data.scenes.map((s, index) => {
        return (
          <div
            key={`pano-key-${index}`}
            className="panorama"
            onClick={() => selectPanorama(s.id)}
          >
            <img src={`./tiles/${s.id}/thumbnail.jpg`} alt=""></img>
            <div className="label-container">
              <div className="label">{state.language[s.name]}</div>
            </div>
          </div>
        );
      });
      content = (
        <>
          <div key="menu-title-pano" className="menu-title">
            {translations.spacesLabel}
          </div>
          <div key="menu-panos" className="panoramas">
            {panoramas}
          </div>
        </>
      );
      break;

    case 1:
      const resources = state.data.settings.resources.content.map(
        (r, index) => {
          if (r.languages.find((l) => l === state.language.languageShort)) {
            return (
              <div
                key={`resource-key-${index}`}
                className="resource"
                onClick={() => {
                  window.open(state.language[r.pdf]);
                  const pdfName = state.language[r.title].replaceAll(" ", "");
                  const langPrefix = state.language.languageShort.toUpperCase();
                  GA.sendEvent(`${langPrefix}_OpenPdf_${pdfName}`, {});
                }}
              >
                <div className="img-container">
                  <img src={r.cover} alt="" />
                </div>
                <div className="resource-title">{state.language[r.title]}</div>
              </div>
            );
          } else {
            return <span key={`resource-key-${index}`}></span>;
          }
        }
      );
      content = (
        <>
          <div key="menu-title-resources" className="menu-title">
            {translations.resourcesLabel}
          </div>
          <div key="menu-resources" className="resources">
            {resources}
          </div>
        </>
      );
      break;

    default:
      break;
  }

  if (state.data.settings.advancedAutoplay?.enabled) {
    return <></>;
  }

  const disabledClass = state.type === "video" ? " disabled" : "";

  return (
    <div className={"preceram-menu-container-desktop" + menuOpenClass}>
      <div className={"content-container"}>
        <CloseSVG className="close" onClick={() => menuClick(false)} />
        {content}
      </div>
      <div className="buttons-container-desktop">
        <div className={"row" + disabledClass}>
          {!state.autoplay && (
            <div
              className={
                selectedSubmenu === 1 && menuOpen ? "button active" : "button"
              }
              onClick={() => {
                const open = selectedSubmenu === 1 && menuOpen ? false : true;
                menuClick(open);
                selectSubmenu(1);
              }}
            >
              <ArticleSVG />
            </div>
          )}
          <div className={"resources-label"}>{translations.resourcesLabel}</div>
        </div>
        <div className={"row" + disabledClass}>
          {!state.autoplay && (
            <div
              className={
                selectedSubmenu === 0 && menuOpen ? "button active" : "button"
              }
              onClick={() => {
                const open = selectedSubmenu === 0 && menuOpen ? false : true;
                menuClick(open);
                selectSubmenu(0);
                const langPrefix = state.language.languageShort.toUpperCase();
                GA.sendEvent(`${langPrefix}_Click_Menu_Spaces`, {});
              }}
            >
              <CollectionsSVG />
            </div>
          )}
          <div className="scene-info">
            <div className="name">{header}</div>
            <div className="progress">{`${
              currentSceneIndex + 1
            }/${nScenes}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
