import React, { FunctionComponent, useContext, useEffect, useRef } from "react";
import { MediaHotspot } from "../../Types/hotspots.types";
import { Context, ExplorerState } from "../Explorer/explorer";
import Marzipano from "../Marzipano/marzipano";
import "./mediaHotspot.scss";

interface MediaHotspotProps {
  data: MediaHotspot;
  id: string;
  scene: string;
}

const MediaHotspotElement: FunctionComponent<MediaHotspotProps> = ({
  data,
  id,
  scene,
}) => {
  const [state]: [ExplorerState, CallableFunction] = useContext(Context);

  const mediaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mediaRef.current) {
      Marzipano.loadHotspot(scene, mediaRef.current, data);
    }
  }, [data, scene]);

  switch (data.media.type) {
    case "image":
      return (
        <div ref={mediaRef} className="media-hotspot" id={id}>
          <img
            width={data.width}
            height={data.height}
            src={state.language[data.media.src]}
            alt=""
          ></img>
        </div>
      );

    case "video":
      return (
        <div ref={mediaRef} className="media-hotspot" id={id}>
          <video
            width={data.width}
            height={data.height}
            src={data.media.src}
            playsInline
          ></video>
        </div>
      );

    default:
      return <></>;
  }
};

export default MediaHotspotElement;
