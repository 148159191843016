import { Hotspot } from "../../Types/hotspots.types";
import { MarzipanoData, MarzipanoScene } from "../../Types/marzipano.types";
import { Guideline } from "../../Types/types";
const Marz = require("marzipano");

export default class Marzipano {
  private static viewer: any;
  private static scenes: any[] = [];

  static config = (element: HTMLDivElement, data: MarzipanoData) => {
    if (!this.viewer) {
      this.scenes = [];

      const viewerOpts = {
        controls: {
          mouseViewMode: data.settings.mouseViewMode,
        },
        stage: { progressive: true },
      };

      this.viewer = new Marz.Viewer(element, viewerOpts);
    }
  };

  static loadScene = (scene: MarzipanoScene) => {
    if (!this.containsScene(scene.id)) {
      const sceneId = scene.id;
      const levels = scene.levels;
      const geometry = new Marz.CubeGeometry(levels);
      const source = Marz.ImageUrlSource.fromString(
        `./tiles/${sceneId}/{z}/{f}/{y}/{x}.jpg`,
        {
          cubeMapPreviewUrl: `./tiles/${scene.id}/preview.jpg`,
        }
      );
      const zoomFactor = scene.zoomFactor ? scene.zoomFactor : 1;
      const faceSize = scene.faceSize;
      const limiter = new Marz.RectilinearView.limit.traditional(
        faceSize * zoomFactor,
        (100 * Math.PI) / 180,
        (120 * Math.PI) / 180
      );
      const view = new Marz.RectilinearView(
        scene.initialViewParameters,
        limiter
      );
      const s = this.viewer.createScene({
        source: source,
        geometry: geometry,
        view: view,
        pinFirstLevel: true,
      });
      // scene.layers?.map((layer) => {
      //   //return this.addLayer(s, scene, layer);
      //   return this.addLayerFromImageFile(s, scene, layer);
      // });
      this.scenes.push({
        data: s,
        id: scene.id,
        initialParams: scene.initialViewParameters,
      });
    }
  };

  static addLayer = (
    sceneMarzipano: any,
    scene: MarzipanoScene,
    layer: string
  ) => {
    if (sceneMarzipano) {
      const source = Marz.ImageUrlSource.fromString(
        `./tiles/${layer}/{z}/{f}/{y}/{x}.jpg`,
        {
          cubeMapPreviewUrl: `./tiles/${layer}/preview.jpg`,
        }
      );
      const geometry = new Marz.CubeGeometry(scene.levels);
      const layerMarzipano = sceneMarzipano.createLayer({
        source: source,
        geometry: geometry,
        layerOpts: { effects: { opacity: 0 } },
      });
      const layerCompleteListener = (e: any) => {
        if (e) {
          if (layerMarzipano.effects().opacity - 0.0001 > 0) {
            layerMarzipano.setEffects({ opacity: 0 });
          } else {
            layerMarzipano.removeEventListener(
              "renderComplete",
              layerCompleteListener
            );
          }
        }
      };
      layerMarzipano.addEventListener("renderComplete", layerCompleteListener);
      return layerMarzipano;
    }
  };

  static fileToCanvas = (file: string, done: CallableFunction) => {
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    let img = document.createElement("img");
    img.onload = () => {
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      ctx?.drawImage(img, 0, 0);
      done(null, canvas);
    };
    img.onerror = (err) => {
      done(err);
    };
    img.src = file;
  };

  static addLayerFromImageFile = (
    sceneMarzipano: any,
    scene: MarzipanoScene,
    layerSrc: string
  ) => {
    this.fileToCanvas(layerSrc, (err: any, canvas: HTMLCanvasElement) => {
      if (err) {
        console.error("Unable to load image file");
        return;
      }
      let asset = new Marz.DynamicAsset(canvas);
      let source = new Marz.SingleAssetSource(asset);
      let geometry = new Marz.EquirectGeometry([{ width: canvas.width }]);
      sceneMarzipano.createLayer({
        source: source,
        geometry: geometry,
      });
    });
  };

  static loadHotspot(
    sceneId: string,
    element: HTMLDivElement,
    hotspot: Hotspot
  ) {
    const scene = this.findById(sceneId);
    const position = { yaw: hotspot.yaw, pitch: hotspot.pitch };
    const perspective = hotspot.perspective ? hotspot.perspective : {};
    scene.data
      .hotspotContainer()
      .createHotspot(element, position, { perspective: perspective });
  }

  static removeHotspot = () => {};

  static findById = (id: string) => {
    return this.scenes.find((s: any) => s.id === id);
  };

  static containsScene = (id: string) => {
    const s = this.findById(id);
    return s !== undefined;
  };

  static changeToScene = (
    id: string,
    mScene: MarzipanoScene,
    done?: CallableFunction,
    duration?: number
  ) => {
    const s = this.findById(id);
    if (s && s.data !== this.viewer.scene()) {
      mScene.layers?.forEach((layer) => {
        this.addLayerFromImageFile(s.data, mScene, layer);
      });
      this.viewer.switchScene(s.data, { transitionDuration: duration }, () => {
        const rest = this.scenes.filter((s) => s.id !== id);
        rest.forEach((rs) => {
          const layers = rs.data.listLayers();
          if (layers.length > 1) {
            layers.forEach((l: any, index: number) => {
              if (index > 0) {
                rs.data.destroyLayer(l);
              }
            });
          }
        });
        this.resetAllLayers(id);
        done && done();
      });
    }
  };

  static lookTo = (
    yaw: number,
    pitch: number,
    duration: number,
    done: CallableFunction
  ) => {
    if (this.viewer) {
      if (!this.viewer.movement()) {
        this.viewer
          .scene()
          .lookTo(
            { yaw: yaw, pitch: pitch },
            { transitionDuration: duration },
            () => done()
          );
      }
    }
  };

  static longLookTo = (
    yaw: number,
    pitch: number,
    duration: number,
    done: CallableFunction
  ) => {
    if (this.viewer) {
      const pos = { yaw: yaw, pitch: pitch };
      this.viewer
        .scene()
        .lookTo(pos, { transitionDuration: duration, shortest: false }, () =>
          done()
        );
    }
  };

  static stopMovement = () => {
    this.viewer.scene().stopMovement();
  };

  static cameraPosition = () => {
    if (this.viewer) {
      const yaw = this.viewer.view().yaw();
      const pitch = this.viewer.view().pitch();

      return { yaw: yaw, pitch: pitch };
    }
    return { yaw: undefined, pitch: undefined };
  };

  static getView() {
    return this.viewer?.view();
  }

  static getProjection() {
    return this.viewer.view().projection();
  }

  static addListenerOnDrag(listener: CallableFunction) {
    this.viewer.view().addEventListener("change", listener);
  }

  static removeListenerOnDrag(listener: CallableFunction) {
    this.viewer.view().removeEventListener("change", listener);
  }

  static resetCameraPosition(spaceId: string) {
    const scene = this.scenes.find((s: any) => s.id === spaceId);
    if (scene) {
      const sceneView = scene.data.view();
      sceneView.setYaw(scene.initialParams.yaw);
      sceneView.setPitch(scene.initialParams.pitch);
      sceneView.setFov(scene.initialParams.fov);
    }
  }

  static resetCameraPositionGuide(guideline: Guideline) {
    if (guideline.initialView) {
      const scene = this.scenes.find((s: any) => s.id === guideline.id);
      if (scene) {
        const sceneView = scene.data.view();
        sceneView.setYaw(guideline.initialView.yaw);
        sceneView.setPitch(guideline.initialView.pitch);
        sceneView.setFov(guideline.initialView.fov);
      }
    } else {
      this.resetCameraPosition(guideline.id);
    }
  }

  static toggleLayer(sceneId: string, layerIdx: number, targetOpacity: number) {
    const scene = this.findById(sceneId);
    if (scene) {
      const layers = scene.data.listLayers();
      if (layers.length > layerIdx) {
        const layer = layers[layerIdx];
        this.layerTransition(layer, targetOpacity, 1000);
      }
    }
  }

  static layerTransition(layer: any, targetOpacity: number, duration: number) {
    const startOpacity = layer.effects().opacity;
    const nIterations = 100;
    for (let i = 0; i < nIterations; i++) {
      setTimeout(() => {
        layer.setEffects({
          opacity:
            startOpacity * ((nIterations - i) / nIterations) +
            targetOpacity * (i / nIterations),
        });
      }, i * (duration / nIterations));
    }
  }

  static resetAllLayers(sceneId: string) {
    const scene = this.findById(sceneId);
    if (scene) {
      const layers = scene.data.listLayers();
      if (layers.length > 1) {
        layers.forEach((layer: any, index: number) => {
          if (index > 0) {
            if (layer.effects().opacity > 0) {
              this.layerTransition(layer, 0, 500);
            }
          }
        });
      }
    }
  }
}
