import React, { FunctionComponent, useContext } from "react";
import { Context, ExplorerState } from "../Explorer/explorer";
import { ReactComponent as HeadphonesSVG } from "../../Icons/Indications/headphones.svg";
import { ReactComponent as MouseSVG } from "../../Icons/Indications/mouse.svg";
import { ReactComponent as ScrollSVG } from "../../Icons/Indications/scroll.svg";
import { ReactComponent as DragSVG } from "../../Icons/Indications/mouse_move.svg";
import "./indicationPanel.scss";
import { TranslationObject } from "../../Types/types";

const IndicationPanel: FunctionComponent = () => {
  const [state]: [ExplorerState, CallableFunction] = useContext(Context);

  const translations: TranslationObject = require(`../../Language/${state.language.languageShort}.json`);

  const hideClass = !state.landingPage ? " hide" : "";

  let indications: JSX.Element[] = [];
  if (state.data.settings.advancedAutoplay.enabled) {
    indications.push(
      <div key={`indication-hp-key`} className="indication alt">
        <HeadphonesSVG />
        <div className="indication-text">
          {translations.putHeadphoneIndication}
        </div>
      </div>
    );
  } else {
    indications.push(
      <div key={`indication-ex-key`} className="indication">
        <DragSVG />
        <div className="indication-text">{translations.exploreIndication}</div>
      </div>
    );
    indications.push(
      <div key={`indication-sl-key`} className="indication">
        <MouseSVG />
        <div className="indication-text">{translations.selectIndication}</div>
      </div>
    );
    indications.push(
      <div key={`indication-zm-key`} className="indication">
        <ScrollSVG />
        <div className="indication-text">{translations.zoomIndication}</div>
      </div>
    );
  }

  return <div className={"indication-panel" + hideClass}>{indications}</div>;
};

export default IndicationPanel;
