import React, { FunctionComponent, useContext } from "react";
import { ReactComponent as ArrowBackSVG } from "../../../Icons/UI/preceram/arrow_back.svg";
import { ReactComponent as ArrowForwardSVG } from "../../../Icons/UI/preceram/arrow_forward.svg";
import { Configs, Guideline } from "../../../Types/types";
import configsJson from "../../../configs.json";
import { ExplorerState, Context } from "../../Explorer/explorer";
import "./navArrows.scss";

const NavArrows: FunctionComponent = () => {
  const [state, setState]: [ExplorerState, CallableFunction] =
    useContext(Context);

  const configs: Configs = configsJson;

  if (state.autoplay || !state.data.settings.navBar.enabled) {
    return <></>;
  }

  let currentSceneIndex = state.data.scenes.findIndex(
    (scene) => scene.id === state.spaceId
  );
  let nScenes = state.data.scenes.length;

  if (state.data.settings.navBar.panoSequence.length > 0) {
    currentSceneIndex = state.data.settings.navBar.panoSequence.findIndex(
      (p) => p.pano === state.spaceId
    );
    nScenes = state.data.settings.navBar.panoSequence.length;
  }

  const backClass = currentSceneIndex === 0 ? "arrow inactive" : "arrow";
  let forwardClass =
    currentSceneIndex === nScenes - 1 ? "arrow inactive" : "arrow";

  if (configs.contactpage.enabled) {
    forwardClass = "arrow";
  }

  const forwardClick = () => {
    if (currentSceneIndex < nScenes - 1) {
      let nextSceneId = state.data.scenes[currentSceneIndex + 1]?.id;
      let type = "360";
      if (state.data.settings.navBar.panoSequence.length > 0) {
        const next =
          state.data.settings.navBar.panoSequence[currentSceneIndex + 1];
        nextSceneId = next.pano;
        type = next.type;
      }

      setState({ ...state, spaceId: nextSceneId, type: type });
    } else {
      if (configs.contactpage.enabled) {
        setState({ ...state, contactPage: true });
      }
    }
  };

  const backClick = () => {
    if (currentSceneIndex > 0) {
      let nextSceneId = state.data.scenes[currentSceneIndex - 1]?.id;
      let type = "360";
      if (state.data.settings.navBar.panoSequence.length > 0) {
        const next =
          state.data.settings.navBar.panoSequence[currentSceneIndex - 1];
        nextSceneId = next.pano;
        type = next.type;
      }

      setState({ ...state, spaceId: nextSceneId, type: type });
    }
  };

  if (state.data.settings.advancedAutoplay?.enabled) {
    const guide: Guideline[] = require(`../../../Data/${state.data.settings.advancedAutoplay.guidelines}`);

    const currentIdx = guide.findIndex((g) => g.id === state.spaceId);
    const nSteps = guide.length;

    const advancedBackClick = () => {
      if (currentIdx > 0) {
        const spaceId = guide[currentIdx - 1].id;
        const type = guide[currentIdx - 1].type;
        setState({ ...state, spaceId: spaceId, type: type });
      }
    };

    const advancedForwardClick = () => {
      if (currentIdx < guide.length - 1) {
        const spaceId = guide[currentIdx + 1].id;
        const type = guide[currentIdx + 1].type;
        setState({ ...state, spaceId: spaceId, type: type });
      }
    };

    if (state.advancedAutoplay) return <></>;

    const backClass = currentIdx === 0 ? " inactive" : "";
    let forwardClass = currentIdx === guide.length - 1 ? " inactive" : "";

    return (
      <div className="preceram-arrows">
        <div className="preceram-arrow">
          <ArrowBackSVG
            className={backClass}
            onClick={() => advancedBackClick()}
          />
        </div>
        <div className="preceram-pano-progress">{`${
          currentIdx + 1
        }/${nSteps}`}</div>
        <div className="preceram-arrow">
          <ArrowForwardSVG
            className={forwardClass}
            onClick={() => advancedForwardClick()}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="preceram-arrows">
      <div className="preceram-arrow">
        <ArrowBackSVG className={backClass} onClick={() => backClick()} />
      </div>
      <div className="preceram-pano-progress">{`${
        currentSceneIndex + 1
      }/${nScenes}`}</div>
      <div className="preceram-arrow">
        <ArrowForwardSVG
          className={forwardClass}
          onClick={() => forwardClick()}
        />
      </div>
    </div>
  );
};

export default NavArrows;
