import React, { FunctionComponent, useContext, useState } from "react";
import GA from "../../../GA/ga";
import { ExplorerState, Context } from "../../Explorer/explorer";
import "./description.scss";

const CHARS_PER_LINE = 37;
const MIN_LINES = 2;

const Description: FunctionComponent = () => {
  const [state]: [ExplorerState, CallableFunction] = useContext(Context);

  const [fullDescription, toggleFullDescription] = useState<boolean>(false);

  if (
    state.hiddenInterface ||
    state.autoplay ||
    state.type === "promo" ||
    state.data.settings.advancedAutoplay?.enabled
  ) {
    return <></>;
  }

  const scene = state.data.scenes.find((s) => s.id === state.spaceId);

  const desc = scene ? state.language[scene.description] : "";

  const lines = fullDescription
    ? { WebkitLineClamp: Math.ceil(desc.length / CHARS_PER_LINE) }
    : { WebkitLineClamp: MIN_LINES };

  return (
    <div
      className="preceram-description"
      onClick={() => {
        toggleFullDescription(!fullDescription);
        const langPrefix = state.language.languageShort.toUpperCase();
        !fullDescription && GA.sendEvent(`${langPrefix}_Click_Info_Spaces`, {});
      }}
      style={lines}
    >
      {desc}
    </div>
  );
};

export default Description;
