import React, { FunctionComponent, useContext, useEffect, useRef } from "react";
import { ReactComponent as ArrowForwardSVG } from "../../Icons/UI/arrow_forward.svg";
import { LinkHotspot } from "../../Types/hotspots.types";
import { TranslationObject } from "../../Types/types";
import { Context, ExplorerState } from "../Explorer/explorer";
import Marzipano from "../Marzipano/marzipano";
import "./linkHotspot.scss";

interface LinkHotspotProps {
  id: string;
  data: LinkHotspot;
  scene: string;
  sfxPlay: CallableFunction;
  sfxPause: CallableFunction;
}

const YAW_OFFSET = 0;
const FLOAT_PRECISION = 0.001;

const LinkHotspotElement: FunctionComponent<LinkHotspotProps> = ({
  id,
  data,
  scene,
}) => {
  const hotspotRef = useRef<HTMLDivElement>(null);

  const [state, setState]: [ExplorerState, CallableFunction] =
    useContext(Context);

  const mobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  useEffect(() => {
    if (hotspotRef.current) {
      Marzipano.loadHotspot(scene, hotspotRef.current, data);
    }

    return () => {
      Marzipano.removeHotspot();
    };
  }, [data, scene]);

  const hotspotClick = () => {
    const hotspot = state.hotspots.find((h) => h.id === id);
    if (!mobile || (hotspot && hotspot.hover)) {
      let newSpace = data.target;
      let type = data.type ? data.type : "360";
      let newData = state.data;
      if (data.targetData) {
        newData = require(`../../Data/${data.targetData}`);
      }
      setState({ ...state, data: newData, spaceId: newSpace, type: type });
    } else {
      //if mobile open preview first
      const { yaw, pitch } = Marzipano.cameraPosition();
      if (
        Math.abs(yaw - (data.yaw + YAW_OFFSET)) < FLOAT_PRECISION &&
        Math.abs(pitch - data.pitch) < FLOAT_PRECISION
      ) {
        onMouseHover(true);
      } else {
        Marzipano.lookTo(data.yaw + YAW_OFFSET, data.pitch, 1000, () => {
          onMouseHover(true);
        });
      }
    }
  };

  const onMouseHover = (hover: boolean) => {
    const tmp = state.hotspots.map((h) => {
      if (h.id === id) {
        let hTmp = h;
        hTmp.hover = hover;
        return hTmp;
      } else {
        let hTmp = h;
        hTmp.hover = false;
        return hTmp;
      }
    });

    setState({ ...state, hotspots: tmp });
  };

  const customRotation = data.rotation ? ` rotate(${data.rotation})` : "";

  const hoverClass = state.hotspots.find((h) => h.id === id)?.hover
    ? " hover"
    : "";

  const greyClass = data.return ? " grey" : "";

  let nextSpaceLabel = data.name
    ? data.name
    : state.data.scenes.find((s) => s.id === data.target)?.name;

  nextSpaceLabel = nextSpaceLabel
    ? state.language[nextSpaceLabel]
    : nextSpaceLabel;

  const thumb = data.targetThumbnail
    ? data.targetThumbnail
    : `./tiles/${data.target}/thumbnail.jpg`;

  const translations: TranslationObject = require(`../../Language/${state.language.languageShort}.json`);

  return (
    <div
      ref={hotspotRef}
      key={id}
      id={id}
      className={"link-hotspot" + hoverClass}
      onMouseOver={() => {
        !mobile && onMouseHover(true);
      }}
      onMouseOut={() => {
        !mobile && onMouseHover(false);
      }}
    >
      <div className={"outter" + greyClass} onClick={() => hotspotClick()}>
        <ArrowForwardSVG
          style={{ transform: "rotate(-90deg)" + customRotation }}
        />
      </div>
      <div
        className={"preview-space" + hoverClass}
        onClick={() => hotspotClick()}
      >
        <img src={thumb} alt=""></img>
        <div className="mask"></div>
        <div className="next-space-container">
          <div className="go-to-label">
            {data.return ? translations.returnTo : translations.goTo}
          </div>
          <div className="next-space">{nextSpaceLabel}</div>
        </div>
      </div>
      <div className={"background-hoverable" + hoverClass}></div>
    </div>
  );
};

export default LinkHotspotElement;
