import React, { FunctionComponent, useContext } from "react";
import { Context, ExplorerState } from "../../Explorer/explorer";
import { ReactComponent as ShareSVG } from "../../../Icons/UI/preceram/share.svg";
import { ReactComponent as WppSVG } from "../../../Icons/UI/preceram/whatsapp.svg";
import { ReactComponent as SoundOnSVG } from "../../../Icons/UI/preceram/volume_up.svg";
import { ReactComponent as SoundOffSVG } from "../../../Icons/UI/preceram/sound_off.svg";
import { ReactComponent as StopSVG } from "../../../Icons/UI/preceram/stop_filled.svg";
import { ReactComponent as RestartSVG } from "../../../Icons/UI/preceram/restart.svg";
import "./toolbar.scss";
import { Guideline } from "../../../Types/types";
import GA from "../../../GA/ga";

const Toolbar: FunctionComponent = () => {
  const [state, setState]: [ExplorerState, CallableFunction] =
    useContext(Context);

  if (
    (state.hiddenInterface && !state.autoplay) ||
    !state.data.settings.navBar.autoplay.enabled
  ) {
    return <></>;
  }

  const shareClick = () => {
    alert("not working");
    const langPrefix = state.language.languageShort.toUpperCase();
    GA.sendEvent(`${langPrefix}_Click_Sharelink`, {});
  };
  let share;
  if (state.data.settings.toolBar.shareLink) {
    share = <ShareSVG onClick={() => shareClick()} />;
  }

  const wppClick = () => {
    alert("not working");
    const langPrefix = state.language.languageShort.toUpperCase();
    GA.sendEvent(`${langPrefix}_Click_Whatsapp`, {});
  };
  let wpp;
  if (state.data.settings.toolBar.whatsapp) {
    wpp = <WppSVG onClick={() => wppClick()} />;
  }

  const soundClick = (sound: boolean) => {
    setState({ ...state, sound: sound });
    const langPrefix = state.language.languageShort.toUpperCase();
    GA.sendEvent(`${langPrefix}_Click_Audio_${sound ? "On" : "Off"}`, {});
  };
  let sound;
  if (state.data.settings.toolBar.enabled) {
    sound = !state.sound ? (
      <SoundOffSVG onClick={() => soundClick(true)} />
    ) : (
      <SoundOnSVG onClick={() => soundClick(false)} />
    );
  }

  if (state.data.settings.advancedAutoplay?.enabled) {
    const guide: Guideline[] = require(`../../../Data/${state.data.settings.advancedAutoplay.guidelines}`);
    const stopClick = () => {
      setState({
        ...state,
        landingPage: true,
        spaceId: guide[0].id,
        type: guide[0].type,
        advancedAutoplay: true,
      });
    };

    let stop;
    if (state.advancedAutoplay) {
      stop = <RestartSVG className="background" onClick={() => stopClick()} />;
    } else {
      stop = <StopSVG className="background" onClick={() => stopClick()} />;
    }

    return (
      <div className="toolbar">
        {stop}
        {sound}
      </div>
    );
  }

  return (
    <div className="toolbar">
      {share}
      {wpp}
      {sound}
    </div>
  );
};

export default Toolbar;
