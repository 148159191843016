import React, { FunctionComponent } from "react";
import Autoplay from "./autoplay";
import Description from "./description";
import Header from "./header";
import Home from "./home";
import Mask from "./mask";
import Menu from "./menu";
import NavArrows from "./navArrows";
import Toolbar from "./toolbar";

const PreceramUI: FunctionComponent = () => {
  return (
    <>
      <Mask />
      <Menu />
      <NavArrows />
      <Autoplay />
      <Toolbar />
      <Description />
      <Header />
      <Home />
    </>
  );
};

export default PreceramUI;
