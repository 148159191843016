import React, { FunctionComponent, useContext, useEffect, useRef } from "react";
import { MarzipanoResonanceAudio } from "../../Types/marzipano.types";
import { Context, ExplorerState } from "../Explorer/explorer";
import Marzipano from "../Marzipano/marzipano";
//import ResonanceAudioScene from "./resonanceAudioScene";
import "./resonanceAudio.scss";
import ResonanceAudioLoad from "./resonanceAudioLoad";

export interface ResonanceAudioHotspot {
  resonanceAudio: JSX.Element;
  sceneId: string;
  id: string;
}

interface ResonanceAudioELementProps {
  scene: string;
  resonanceAudioParams: MarzipanoResonanceAudio;
}

const ResonanceAudioElement: FunctionComponent<ResonanceAudioELementProps> = ({
  scene,
  resonanceAudioParams,
}) => {
  //const audioRef = useRef<HTMLAudioElement>(null);
  const ra = useRef<ResonanceAudioLoad | null>(null);

  // const [audioPosition, changeAudioPosition] = useState<Position>(
  //   resonanceAudioParams.audio.position
  // );

  const [state]: [ExplorerState, CallableFunction] = useContext(Context);

  useEffect(() => {
    return () => {
      if (ra.current) {
        ra.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    ra.current = new ResonanceAudioLoad(
      resonanceAudioParams.audio.position,
      resonanceAudioParams.audio.maxDistance,
      { x: 0, y: 0, z: 0 },
      resonanceAudioParams.audio.source,
      resonanceAudioParams.room.dimensions,
      resonanceAudioParams.room.materials
    );
  }, [resonanceAudioParams]);

  useEffect(() => {
    if (
      state.spaceId === scene &&
      ra.current &&
      !state.landingPage &&
      !state.contactPage
    ) {
      // ra.current.play();
      ra.current.changeVolume(true);
    } else {
      //ra.current?.pause();
      ra.current?.changeVolume(false);
    }
  }, [state.spaceId, scene, state.landingPage, state.contactPage]);

  useEffect(() => {
    if (state.sound) {
      ra.current?.unmute();
    } else {
      ra.current?.mute();
    }
  }, [state.sound]);

  useEffect(() => {
    const listener = () => {
      // const matrixMarzipano: Float32Array = Marzipano.getProjection();
      // const matrix = new THREE.Matrix4();
      // matrix.set(
      //   matrixMarzipano[0],
      //   matrixMarzipano[1],
      //   matrixMarzipano[2],
      //   matrixMarzipano[3],
      //   matrixMarzipano[4],
      //   matrixMarzipano[5],
      //   matrixMarzipano[6],
      //   matrixMarzipano[7],
      //   matrixMarzipano[8],
      //   matrixMarzipano[9],
      //   matrixMarzipano[10],
      //   matrixMarzipano[11],
      //   matrixMarzipano[12],
      //   matrixMarzipano[13],
      //   matrixMarzipano[14],
      //   matrixMarzipano[15]
      // );
      const { yaw, pitch } = Marzipano.cameraPosition();

      const forwardX = Math.cos(yaw) * Math.cos(pitch);
      const forwardY = Math.sin(yaw);
      const forwardZ = Math.sin(pitch);

      const upX = 0;
      const upY = 0;
      const upZ = 1;
      if (ra.current) {
        // ra.current.changeListenerMatrix(matrix);
        ra.current.changeListenerOrientation(
          forwardX,
          forwardY,
          forwardZ,
          upX,
          upY,
          upZ
        );
      }
    };
    if (
      state.spaceId === scene &&
      ra.current &&
      !state.landingPage &&
      !state.contactPage
    ) {
      Marzipano.addListenerOnDrag(listener);
    } else {
      Marzipano.removeListenerOnDrag(listener);
    }

    return () => {
      Marzipano.removeListenerOnDrag(listener);
    };
  }, [
    state.spaceId,
    scene,
    state.landingPage,
    state.contactPage,
    resonanceAudioParams,
  ]);

  // useEffect(() => {
  //   ra.current?.changeAudioPosition(audioPosition);
  // }, [audioPosition]);

  return <></>;
};

export default ResonanceAudioElement;
