import React, { FunctionComponent, useContext } from "react";
import { Context, ExplorerState } from "../../../Explorer/explorer";
import Business from "./business/business";
import GuideBar from "./guideBar";
//import Autoplay from "./autoplay";
//import Description from "./description";
//import Header from "./header";
import Home from "./home";
import Mask from "./mask";
import Menu from "./menu";
import NavArrows from "./navArrows";
import Toolbar from "./toolbar";

const PreceramUI: FunctionComponent = () => {
  const [state]: [ExplorerState, CallableFunction] = useContext(Context);

  if (state.business.active) {
    return (
      <>
        <Mask />
        <Home />
        <Business />
      </>
    );
  }

  return (
    <>
      <Mask />
      <Menu />
      <GuideBar />
      <NavArrows />
      <Toolbar />
      <Home />
    </>
  );
};

export default PreceramUI;
