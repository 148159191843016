import React, { FunctionComponent, useContext, useEffect, useRef } from "react";
import { ModalHotspot } from "../../Types/hotspots.types";
import { ExplorerState, Context } from "../Explorer/explorer";
import Marzipano from "../Marzipano/marzipano";
import "./modalHotspot.scss";
import configsJson from "../../configs.json";
import { Configs, Guideline } from "../../Types/types";
import GA from "../../GA/ga";
import enJson from "../../Data/en.json";

interface ModalHotspotProps {
  scene: string;
  id: string;
  data: ModalHotspot;
  sfxPlay: CallableFunction;
  sfxPause: CallableFunction;
}

const FLOAT_PRECISION = 0.001;

const ModalHotspotElement: FunctionComponent<ModalHotspotProps> = ({
  id,
  data,
  scene,
  sfxPlay,
}) => {
  const mobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const hotspotRef = useRef<HTMLDivElement>(null);

  const [state, setState]: [ExplorerState, CallableFunction] =
    useContext(Context);

  const configs: Configs = configsJson;

  useEffect(() => {
    if (hotspotRef.current) {
      Marzipano.loadHotspot(scene, hotspotRef.current, data);
    }

    return () => {
      Marzipano.removeHotspot();
    };
  }, [data, scene]);

  const activate = () => {
    const tmp = state.hotspots.map((h) => {
      if (h.id === id) {
        let hTmp = h;
        hTmp.active = true;
        return hTmp;
      } else {
        return h;
      }
    });

    const langPrefix = state.language.languageShort.toUpperCase();
    for (const [key, value] of Object.entries(enJson)) {
      if (key === data.title) {
        GA.sendEvent(
          `${langPrefix}_Open_Modal_Hotspot_${
            data.tag ? data.tag : value.replaceAll(" ", "_")
          }`,
          {}
        );
      }
    }

    const { yaw, pitch } = Marzipano.cameraPosition();
    if (
      Math.abs(yaw - data.yaw) < FLOAT_PRECISION &&
      Math.abs(pitch - data.pitch) < FLOAT_PRECISION
    ) {
      sfxPlay();
      setState({ ...state, hotspots: tmp });
    } else {
      Marzipano.lookTo(data.yaw, data.pitch, data.cameraDuration, () => {
        sfxPlay();
        setState({ ...state, hotspots: tmp });
      });
    }
  };

  const onMouseHover = (hover: boolean) => {
    const tmp = state.hotspots.map((h) => {
      if (h.id === id) {
        let hTmp = h;
        hTmp.hover = hover;
        return hTmp;
      } else {
        return h;
      }
    });

    setState({ ...state, hotspots: tmp });
  };

  const deactivate = () => {
    const tmp = state.hotspots.map((h) => {
      h.active = false;
      h.hover = false;
      return h;
    });

    setState({ ...state, hotspots: tmp });
  };

  const activeClass = state.hotspots.find((h) => h.id === id)?.active
    ? " active"
    : "";

  let Template;
  try {
    Template = require(`./modalTemplates/${!mobile ? "desktop/" : ""}${
      data.template
    }.template.tsx`).default;
  } catch (e) {
    //console.error(`${data.template} template does not exist.`);
    return <></>;
  }

  const hoverClass = state.hotspots.find((h) => h.id === id)?.hover
    ? " hover"
    : "";

  const innerIcon = <img src={configs.logoIcon} alt="" />;

  const hoverLabel = (
    <div className="hover-label">{state.language[data.title]}</div>
  );

  let display = {};
  if (state.data.settings.advancedAutoplay?.enabled) {
    const guide: Guideline[] = require(`../../Data/${state.data.settings.advancedAutoplay.guidelines}`);
    const guideScene = guide.find((g) => g.id === scene);
    if (guideScene) {
      const idx = id.split("-").pop();
      const actionTarget = `modal-hotspot-${idx}`;
      const action = guideScene.actions.find((a) => a.target === actionTarget);
      if (!action) {
        display = { opacity: 0 };
      }
    }
  }

  return (
    <>
      <div
        ref={hotspotRef}
        key={id}
        id={id}
        className="modal-hotspot"
        style={{ ...display }}
      >
        <div
          className={"hotspot" + activeClass + hoverClass}
          onClick={() => activate()}
          onMouseOver={() => {
            !state.hotspots.find((h) => h.id === id)?.active &&
              onMouseHover(true);
          }}
          onMouseOut={() => {
            !state.hotspots.find((h) => h.id === id)?.active &&
              onMouseHover(false);
          }}
        >
          {innerIcon}
          {hoverLabel}
        </div>
      </div>
      {Template && (
        <Template
          data={data}
          deactivate={() => deactivate()}
          activeClass={activeClass}
        />
      )}
    </>
  );
};

export default ModalHotspotElement;
