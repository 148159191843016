import React, { FunctionComponent, useContext, useState } from "react";
import { Context, ExplorerState, SFXContext } from "../Explorer/explorer";
import configsJson from "../../configs.json";
import {
  Configs,
  JsonLanguageObject,
  TranslationObject,
} from "../../Types/types";
import { ReactComponent as AVPoweredSVG } from "../../Icons/Images/alive-vision.svg";
import { ReactComponent as ArrowForwardSVG } from "../../Icons/UI/arrow_forward.svg";
import { ReactComponent as PartnersSVG } from "../../Icons/Images/preceram-partners.svg";
import { ReactComponent as DropDownSVG } from "../../Icons/UI/arrow_drop_down.svg";
import { ReactComponent as ENFlagSVG } from "../../Icons/Flags/GB.svg";
import { ReactComponent as PTFlagSVG } from "../../Icons/Flags/PT.svg";
import { ReactComponent as ESFlagSVG } from "../../Icons/Flags/ES.svg";
import { ReactComponent as FRFlagSVG } from "../../Icons/Flags/FR.svg";
import "./preceram.scss";
import GA from "../../GA/ga";

const LandingPage: FunctionComponent = () => {
  const [state, setState]: [ExplorerState, CallableFunction] =
    useContext(Context);

  const sfxPlay = useContext(SFXContext);

  const [langMenu, toggleLangMenu] = useState<boolean>(false);

  const configs: Configs = configsJson;

  const title = configs.landingpage.title;
  const description = configs.landingpage.description;

  const closedClass = state.landingPage ? "" : " closed";

  const data = require(`../../Data/${configs.landingpage.data[0].data}`);

  const translations: TranslationObject = require(`../../Language/${state.language.languageShort}.json`);

  const underlineVideoClick = () => {
    const langPrefix = state.language.languageShort.toUpperCase();
    GA.sendEvent(`${langPrefix}_Click_Video_Link`, {});
    window.open(state.language[configs.landingpage.videoURL]);
  };
  const clickHere: (string | JSX.Element)[] = translations.clickHereVideo.split(
    translations.underlineSeeHere
  );
  clickHere.splice(
    1,
    0,
    <u key="underline-see-here" onClick={() => underlineVideoClick()}>
      {translations.underlineSeeHere}
    </u>
  );

  const changeLanguage = (
    newLanguage: JsonLanguageObject,
    e?: React.MouseEvent<HTMLDivElement>
  ) => {
    e?.stopPropagation();
    setState({ ...state, language: newLanguage });
    toggleLangMenu(false);
  };

  const languages = configs.multilanguage.languages.map((l, index) => {
    try {
      const languageJson: JsonLanguageObject = require(`../../Data/${l}.json`);
      let flag = <PTFlagSVG className="flag" />;
      switch (l) {
        case "en":
          flag = <ENFlagSVG className="flag" />;
          break;

        case "pt":
          flag = <PTFlagSVG className="flag" />;
          break;

        case "es":
          flag = <ESFlagSVG className="flag" />;
          break;

        case "fr":
          flag = <FRFlagSVG className="flag" />;
          break;
      }

      return (
        <div
          key={`language-selector-${index}`}
          className="lang-item"
          onClick={(e) => changeLanguage(languageJson, e)}
        >
          <span>{languageJson.language}</span>
          {flag}
        </div>
      );
    } catch (e) {
      console.warn(`${l}.json language file does not exist on Data folder`);
      return (
        <div key={`language-selector-${index}`} className="language">
          <div className="label"></div>
          <div className="flag"></div>
        </div>
      );
    }
  });

  const langMenuClass = langMenu ? "menu open" : "menu";

  return (
    <div
      className={"preceram-landing-page" + closedClass}
      onClick={() => langMenu && toggleLangMenu(false)}
    >
      <img
        className="background"
        src={state.language[configs.landingpage.background]}
        alt=""
      ></img>
      <div className="focus"></div>
      <div className="header">
        <img
          className="logo"
          src={state.language[configs.landingpage.logo]}
          alt=""
        ></img>
        <div
          className="language-selector"
          onClick={() => !langMenu && toggleLangMenu(true)}
        >
          <div className="lang">{state.language.languageShort}</div>
          <DropDownSVG />
          <div className={langMenuClass}>{languages}</div>
        </div>
      </div>
      <div className="content-container">
        <div className="title">{state.language[title]}</div>
        <div className="description">{state.language[description]}</div>
        <div className="btn-container">
          <div
            className="start-btn"
            onClick={(e) => {
              e.stopPropagation();
              const newData = { ...data };
              if (newData.settings.advancedAutoplay) {
                newData.settings.advancedAutoplay.enabled = false;
              }
              const langPrefix = state.language.languageShort.toUpperCase();
              GA.sendEvent(`${langPrefix}_Click_Interactive`, {});
              sfxPlay();
              setState({
                ...state,
                landingPage: false,
                spaceId: configs.landingpage.data[0].scene,
                type: configs.landingpage.data[0].type,
                data: newData,
              });
            }}
          >
            <div className="start-label">{translations.startVisit360}</div>
            <ArrowForwardSVG />
          </div>
          <div
            className="start-btn alt"
            onClick={(e) => {
              e.stopPropagation();
              const newData = { ...data };
              if (newData.settings.advancedAutoplay) {
                newData.settings.advancedAutoplay.enabled = true;
              }
              const langPrefix = state.language.languageShort.toUpperCase();
              GA.sendEvent(`${langPrefix}_Click_Guided`, {});
              sfxPlay();
              setState({
                ...state,
                landingPage: false,
                spaceId: configs.landingpage.data[0].scene,
                type: configs.landingpage.data[0].type,
                data: newData,
              });
            }}
          >
            <div className="start-label">{translations.startVisitGuided}</div>
            <ArrowForwardSVG />
          </div>
        </div>
        <div className="watch-video-here">{clickHere}</div>
      </div>
      <div className="footer">
        <PartnersSVG className="partners" />
        <AVPoweredSVG className="powered-by" />
      </div>
    </div>
  );
};

export default LandingPage;
