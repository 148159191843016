import React, { FunctionComponent, useContext } from "react";
import { Context, ExplorerState } from "../Explorer/explorer";
import SceneAudio from "./sceneAudio";

const AudioContainer: FunctionComponent = () => {
  const [state]: [ExplorerState, CallableFunction] = useContext(Context);

  const sceneAudios: JSX.Element[] = [];

  state.circuits.forEach((c, cIndex) => {
    c.scenes.forEach((s, index) => {
      if (s.audio) {
        sceneAudios.push(
          <SceneAudio
            key={`c${cIndex}-s${index}-audio`}
            scene={s.id}
            audio={s.audio}
          />
        );
      }
    });
  });

  return <div className="audio-container">{sceneAudios}</div>;
};

export default AudioContainer;
