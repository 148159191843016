import React, { FunctionComponent, useContext, useState } from "react";
import { ModalHotspot } from "../../../../Types/hotspots.types";
import { ReactComponent as CloseSVG } from "../../../../Icons/UI/preceram/close.svg";
import { ReactComponent as InfoSVG } from "../../../../Icons/UI/preceram/info.svg";
import "./preceram.template.scss";
import { Context, ExplorerState } from "../../../Explorer/explorer";

interface ModalHotspotPreceramTemplate extends ModalHotspot {
  description: string;
  image: string;
}

interface PreceramTemplateProps {
  data: ModalHotspotPreceramTemplate;
  activeClass: string;
  deactivate: CallableFunction;
}

const PreceramTemplateDesktop: FunctionComponent<PreceramTemplateProps> = ({
  data,
  activeClass,
  deactivate,
}) => {
  const [state]: [ExplorerState, CallableFunction] = useContext(Context);

  const [infoOpen, openInfo] = useState<boolean>(false);

  const toggle = (open: boolean) => {
    openInfo(open);
  };

  const openClass = infoOpen ? " open" : "";

  const description = data.description;

  return (
    <div className={"preceram-modal-template-desktop" + activeClass}>
      <div className="focus" onClick={() => deactivate()}></div>
      <div className="modal-container">
        <div className="image">
          <img src={state.language[data.image]} alt=""></img>
        </div>
        <div className="close" onClick={() => deactivate()}>
          <CloseSVG />
        </div>
        <div
          className={"description" + openClass}
          onClick={() => toggle(!infoOpen)}
        >
          <div className="header">
            <div className="title">{state.language[data.title]}</div>
            <InfoSVG />
          </div>
          <div className="body">{state.language[description]}</div>
        </div>
      </div>
    </div>
  );
};

export default PreceramTemplateDesktop;
