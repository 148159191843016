import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Guideline, GuidelineAction, SubtitleObject } from "../../Types/types";
import { Context, ExplorerState } from "../Explorer/explorer";
import Marzipano from "../Marzipano/marzipano";
import "./advancedAutoplay.scss";

interface AdvancedAudioProps {
  guide: Guideline[];
  selfIdx: number;
}

const AdvancedAudio: FunctionComponent<AdvancedAudioProps> = ({
  guide,
  selfIdx,
}) => {
  const [state, setState]: [ExplorerState, CallableFunction] =
    useContext(Context);

  const [subtitle, changeSubtitle] = useState<string>("");

  const searchParams = new URLSearchParams(window.location.search);

  let subJson: SubtitleObject[] = [];
  try {
    subJson = require(`../../Data/subtitles.json`);
  } catch (e) {
    //skip
  }
  const subtitleObject: SubtitleObject | undefined =
    subJson && searchParams.get("controls") === "false"
      ? subJson.find((s) => s.id === guide[selfIdx].id)
      : undefined;

  const audioRef = useRef<HTMLAudioElement>(null);
  const audioAmbRef = useRef<HTMLAudioElement>(null);

  const currentTimeSeconds = useRef<number>(-1);

  useEffect(() => {
    Marzipano.resetCameraPositionGuide(guide[selfIdx]);
  }, [guide, selfIdx]);

  useEffect(() => {
    const endEvent = () => {
      if (!state.landingPage) {
        if (guide.length > selfIdx + 1) {
          setState({
            ...state,
            spaceId: guide[selfIdx + 1].id,
            type: guide[selfIdx + 1].type,
          });
        } else {
          setState({
            ...state,
            landingPage: true,
            spaceId: guide[0].id,
            type: guide[0].type,
            advancedAutoplay: true,
          });
        }
      }
    };
    if (audioRef.current && state.spaceId === guide[selfIdx].id) {
      audioRef.current.onended = () => endEvent();
    }
  }, [guide, selfIdx, state, setState]);

  useEffect(() => {
    const performAction = (action: GuidelineAction) => {
      switch (action.action) {
        case "open":
          const targetTmp = action.target.split("-");
          const hotspotId =
            `${state.data.name}-${guide[selfIdx].id}-${targetTmp[0]}-${targetTmp[2]}`.toLowerCase();
          const newHotspots = state.hotspots.map((h) => {
            let newHotspot = h;
            if (h.id === hotspotId) newHotspot.active = true;
            return newHotspot;
          });
          setState({ ...state, hotspots: newHotspots });
          break;

        case "close":
          const closeTmp = action.target.split("-");
          const closeId =
            `${state.data.name}-${guide[selfIdx].id}-${closeTmp[0]}-${closeTmp[2]}`.toLowerCase();
          const newClosed = state.hotspots.map((h) => {
            let newHotspot = h;
            if (h.id === closeId) newHotspot.active = false;
            return newHotspot;
          });
          setState({ ...state, hotspots: newClosed });
          break;

        case "show":
          const showHTmp = action.target.split("-");
          const showHId =
            `${state.data.name}-${guide[selfIdx].id}-${showHTmp[0]}-${showHTmp[2]}`.toLowerCase();
          const newHtsptShow = state.hotspots.map((h) => {
            let newHotspot = h;
            if (h.id === showHId) newHotspot.hover = true;
            return newHotspot;
          });
          setState({ ...state, hotspots: newHtsptShow });
          break;

        case "hide":
          const hideHTmp = action.target.split("-");
          const hideHId =
            `${state.data.name}-${guide[selfIdx].id}-${hideHTmp[0]}-${hideHTmp[2]}`.toLowerCase();
          const newHtsptHide = state.hotspots.map((h) => {
            let newHotspot = h;
            if (h.id === hideHId) newHotspot.hover = false;
            return newHotspot;
          });
          setState({ ...state, hotspots: newHtsptHide });
          break;

        case "showLayer":
          const showTmp = parseInt(action.target);
          Marzipano.toggleLayer(state.spaceId, showTmp, 1);
          break;

        case "hideLayer":
          const hideTmp = parseInt(action.target);
          Marzipano.toggleLayer(state.spaceId, hideTmp, 0);
          break;

        case "move":
          const cameraCoords = action.target.split(" ");
          const yaw = parseFloat(cameraCoords[0]);
          const pitch = parseFloat(cameraCoords[1]);
          action.duration &&
            Marzipano.lookTo(yaw, pitch, action.duration * 1000, () => {});
          break;

        default:
          break;
      }
    };

    const showSubtitle = (s: string) => {
      changeSubtitle(s);
    };

    const timeUpdateEvent = () => {
      if (audioRef.current) {
        const tmpTime = Math.floor(audioRef.current.currentTime);
        if (
          tmpTime > currentTimeSeconds.current &&
          audioRef.current.currentTime > 0
        ) {
          const activeActions = guide[selfIdx].actions.filter(
            (a) => a.trigger === tmpTime
          );

          activeActions.forEach((a) => {
            performAction(a);
          });

          if (subtitleObject) {
            const subtitlesActive: {
              subtitle: string;
              start: number;
              stop: number;
            }[] = [];
            subtitleObject.subtitles.forEach((s) => {
              if (s.start <= tmpTime && s.stop > tmpTime) {
                subtitlesActive.push(s);
              }
            });
            if (subtitlesActive.length > 0) {
              showSubtitle(subtitlesActive[0].subtitle);
            } else {
              showSubtitle("");
            }
          }

          currentTimeSeconds.current = tmpTime;
        }
      }
    };

    if (audioRef.current) {
      audioRef.current.ontimeupdate = () => {
        timeUpdateEvent();
      };
    }
  }, [guide, selfIdx, state, setState, subtitleObject]);

  useEffect(() => {
    if (audioRef.current) {
      if (
        guide[selfIdx].id === state.spaceId &&
        !state.landingPage &&
        !state.contactPage
      ) {
        if (state.advancedAutoplay) {
          // audioRef.current.load();
          audioRef.current.play();
          audioRef.current.volume = 1;
          // audioAmbRef.current && audioAmbRef.current.load();
          audioAmbRef.current && audioAmbRef.current.play();
          guide[selfIdx].actions.forEach((a) => {
            if (audioRef.current) {
              if (
                a.action === "move" &&
                a.duration &&
                a.trigger + a.duration > currentTimeSeconds.current &&
                a.trigger <= currentTimeSeconds.current
              ) {
                const cameraCoords = a.target.split(" ");
                const yaw = parseFloat(cameraCoords[0]);
                const pitch = parseFloat(cameraCoords[1]);
                const remainDuration =
                  a.duration + a.trigger - currentTimeSeconds.current;
                Marzipano.lookTo(yaw, pitch, remainDuration * 1000, () => {});
              }
            }
          });
        } else {
          audioRef.current.pause();
          audioAmbRef.current && audioAmbRef.current.pause();
          Marzipano.stopMovement();
        }
      } else {
        if (audioRef.current.currentTime > 0) {
          audioRef.current.pause();
          audioAmbRef.current && audioAmbRef.current.pause();
          audioRef.current.currentTime = 0;
          currentTimeSeconds.current = -1;
          const tmpHotspots = state.hotspots.map((h) => {
            const newH = h;
            newH.active = false;
            newH.hover = false;
            return newH;
          });
          setState({ ...state, tmpHotspots });
          setTimeout(() => {
            Marzipano.resetCameraPositionGuide(guide[selfIdx]);
            Marzipano.resetAllLayers(guide[selfIdx].id);
          }, 1000);
        }
      }
    }
  }, [guide, selfIdx, state, setState]);

  // useEffect(() => {
  //   if (audioRef.current) audioRef.current.muted = !state.sound;
  //   if (audioAmbRef.current) audioAmbRef.current.muted = !state.sound;
  // }, [state.sound]);

  const media = guide[selfIdx].media;
  const mediaSrc = media ? state.language[media.src] : "";

  const amb = media ? media.srcAmb : undefined;
  const srcAmb = amb ? state.language[amb] : "";

  return (
    <>
      <audio
        ref={audioRef}
        src={mediaSrc}
        muted={!state.sound}
        preload="auto"
      ></audio>
      {srcAmb && <audio ref={audioAmbRef} src={srcAmb} preload="auto"></audio>}
      <div className="subtitle">{subtitle}</div>
    </>
  );
};

export default AdvancedAudio;
