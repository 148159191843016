import React, { FunctionComponent, useContext } from "react";
import {
  InfoHotspot,
  LinkHotspot,
  MediaHotspot,
  ModalHotspot,
} from "../../Types/hotspots.types";
import { ExplorerState, Context, SFXContext } from "../Explorer/explorer";
import InfoHotspotElement from "./infoHotspot";
import LinkHotspotElement from "./linkHotspot";
import MediaHotspotElement from "./mediaHotspot";
import ModalHotspotElement from "./modalHotspot";

const HotspotContainer: FunctionComponent = () => {
  const [state]: [ExplorerState, CallableFunction] = useContext(Context);

  const sfxPlay = useContext(SFXContext);
  const sfxPause = () => {};

  const hotspots = state.hotspots.map((h) => {
    let element;
    switch (h.type) {
      case "info":
        element = (
          <InfoHotspotElement
            key={h.id}
            id={h.id}
            data={h.data as InfoHotspot}
            scene={h.sceneId}
            sfxPlay={sfxPlay}
            sfxPause={sfxPause}
          />
        );
        break;

      case "link":
        element = (
          <LinkHotspotElement
            key={h.id}
            id={h.id}
            data={h.data as LinkHotspot}
            scene={h.sceneId}
            sfxPlay={sfxPlay}
            sfxPause={sfxPause}
          />
        );
        break;

      case "modal":
        element = (
          <ModalHotspotElement
            key={h.id}
            id={h.id}
            data={h.data as ModalHotspot}
            scene={h.sceneId}
            sfxPlay={sfxPlay}
            sfxPause={sfxPause}
          />
        );
        break;

      case "media":
        element = (
          <MediaHotspotElement
            key={h.id}
            id={h.id}
            data={h.data as MediaHotspot}
            scene={h.sceneId}
          />
        );
        break;
    }

    return element;
  });

  return <>{hotspots}</>;
};

export default HotspotContainer;
