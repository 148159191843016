import React, { FunctionComponent, useContext, useState } from "react";
import { ReactComponent as GridSVG } from "../../../Icons/UI/preceram/grid_view_fill.svg";
import { ReactComponent as CloseSVG } from "../../../Icons/UI/preceram/close.svg";
import { ReactComponent as CollectionsSVG } from "../../../Icons/UI/preceram/collections.svg";
import { ReactComponent as ArticleSVG } from "../../../Icons/UI/preceram/article.svg";
import { Context, ExplorerState } from "../../Explorer/explorer";
import configsJson from "../../../configs.json";
import "./menu.scss";
import { Configs } from "../../../Types/types";
import GA from "../../../GA/ga";

const Menu: FunctionComponent = () => {
  const [state, setState]: [ExplorerState, CallableFunction] =
    useContext(Context);

  const [menuOpen, toggleMenu] = useState<boolean>(false);
  const [selectedSubmenu, selectSubmenu] = useState<number>(0);

  const configs: Configs = configsJson;

  const translations = require(`../../../Language/${state.language.languageShort}.json`);

  if (
    state.hiddenInterface ||
    state.autoplay ||
    state.data.settings.advancedAutoplay?.enabled
  ) {
    return <></>;
  }

  const menuClick = (open: boolean) => {
    toggleMenu(open);
  };

  const menuOpenClass = menuOpen ? " open" : "";

  const submenus = [
    { label: translations.spacesLabel, svg: <CollectionsSVG /> },
    { label: translations.resourcesLabel, svg: <ArticleSVG /> },
  ];

  const submenuElements: JSX.Element[] = submenus.map((s, index) => {
    if (index === 1 && !state.data.settings.resources.enabled) return <></>;
    const selectedClass = index === selectedSubmenu ? " selected" : "";
    return (
      <div
        key={`submenu-key-${index}`}
        className={"submenu-item" + selectedClass}
        onClick={() => {
          selectSubmenu(index);
          if (index === 0) {
            const langPrefix = state.language.languageShort.toUpperCase();
            GA.sendEvent(`${langPrefix}_Click_Menu_Spaces`, {});
          }
        }}
      >
        {s.svg}
        <div className="label">{s.label}</div>
      </div>
    );
  });

  const selectPanorama = (id: string) => {
    toggleMenu(false);
    setState({ ...state, spaceId: id, type: "360" });
  };

  let content = <></>;

  switch (selectedSubmenu) {
    case 0:
      const panoramas = state.data.scenes.map((s, index) => {
        return (
          <div
            key={`pano-key-${index}`}
            className="panorama"
            onClick={() => {
              selectPanorama(s.id);
            }}
          >
            <img src={`./tiles/${s.id}/thumbnail_mob.jpg`} alt=""></img>
            <div className="label-container">
              <div className="label">{state.language[s.name]}</div>
            </div>
          </div>
        );
      });
      content = <div className="panoramas">{panoramas}</div>;
      break;

    case 1:
      const brochuras = state.data.settings.resources.content.map(
        (r, index) => {
          if (r.languages.find((l) => l === state.language.languageShort)) {
            return (
              <div
                key={`resource-key-${index}`}
                className="resource"
                onClick={() => {
                  window.open(state.language[r.pdf]);
                  const pdfName = state.language[r.title].replaceAll(" ", "");
                  const langPrefix = state.language.languageShort.toUpperCase();
                  GA.sendEvent(`${langPrefix}_OpenPdf_${pdfName}`, {});
                }}
              >
                <img src={r.cover} alt="" />
              </div>
            );
          } else {
            return <span key={`resource-key-${index}`}></span>;
          }
        }
      );
      content = <div className="panoramas">{brochuras}</div>;
      break;

    default:
      break;
  }

  return (
    <>
      <div className="preceram-menu" onClick={() => menuClick(true)}>
        <GridSVG />
      </div>
      <div className={"preceram-menu-container" + menuOpenClass}>
        <img className="logo" src={state.language[configs.logo]} alt=""></img>
        <CloseSVG className="close" onClick={() => menuClick(false)} />
        <div className="submenu">{submenuElements}</div>
        {content}
      </div>
    </>
  );
};

export default Menu;
