import React, { FunctionComponent, useContext } from "react";
import { ReactComponent as PlaySVG } from "../../../Icons/UI/preceram/play_circle.svg";
import { ReactComponent as PauseSVG } from "../../../Icons/UI/preceram/pause_circle.svg";
import { ExplorerState, Context } from "../../Explorer/explorer";
import "./autoplay.scss";

const Autoplay: FunctionComponent = () => {
  const [state, setState]: [ExplorerState, CallableFunction] =
    useContext(Context);

  if (
    (state.hiddenInterface && !state.autoplay) ||
    !state.data.settings.navBar.autoplay.enabled
  ) {
    return <></>;
  }

  const toggleAutoplay = (autoplay: boolean) => {
    setState({ ...state, autoplay: autoplay });
  };

  const toggleAdvancedAutoplay = (autoplay: boolean) => {
    setState({ ...state, advancedAutoplay: autoplay });
  };

  if (state.data.settings.advancedAutoplay?.enabled) {
    return (
      <div className="preceram-autoplay-container">
        {state.advancedAutoplay ? (
          <PauseSVG
            className="pause"
            onClick={() => toggleAdvancedAutoplay(false)}
          />
        ) : (
          <PlaySVG
            className="play"
            onClick={() => toggleAdvancedAutoplay(true)}
          />
        )}
      </div>
    );
  }

  return (
    <div className="preceram-autoplay-container disabled">
      {state.autoplay ? (
        <PauseSVG className="pause" onClick={() => toggleAutoplay(false)} />
      ) : (
        <PlaySVG className="play" onClick={() => toggleAutoplay(true)} />
      )}
    </div>
  );
};

export default Autoplay;
