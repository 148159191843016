import React, { FunctionComponent } from "react";
import "./mask.scss";

const Mask: FunctionComponent = () => {
  return (
    <>
      <div className="top-mask"></div>
      <div className="bottom-mask"></div>
    </>
  );
};

export default Mask;
