import React, { FunctionComponent, useContext } from "react";
import { Context, ExplorerState } from "../../Explorer/explorer";
import "./header.scss";
import promoPagesJson from "../../../Data/promo_pages.json";
import { PromoPage } from "../../../Types/types";

const Header: FunctionComponent = () => {
  const [state]: [ExplorerState, CallableFunction] = useContext(Context);

  const promoPages: PromoPage[] = promoPagesJson;

  let header = state.data.scenes.find((s) => s.id === state.spaceId)?.name;
  if (state.type === "promo") {
    header = promoPages[parseInt(state.spaceId)].name;
  }
  header = header ? state.language[header] : header;

  if (!state.data.settings.nameOnTop.enabled || state.type === "promo") {
    return <></>;
  }

  let currentSceneIndex = state.data.scenes.findIndex(
    (scene) => scene.id === state.spaceId
  );
  let nScenes = state.data.scenes.length;

  if (state.data.settings.navBar.panoSequence.length > 0) {
    currentSceneIndex = state.data.settings.navBar.panoSequence.findIndex(
      (p) => p.pano === state.spaceId
    );
    nScenes = state.data.settings.navBar.panoSequence.length;
  }

  return (
    <>
      <div className="preceram-header">{[header]}</div>
      {state.autoplay && (
        <div className="preceram-header-progress">{`${
          currentSceneIndex + 1
        }/${nScenes}`}</div>
      )}
    </>
  );
};

export default Header;
