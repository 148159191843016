import React, { FunctionComponent, useContext, useRef, useState } from "react";
import configsJson from "../../configs.json";
import { ReactComponent as AVPoweredSVG } from "../../Icons/Images/alive-vision.svg";
import { ReactComponent as ArrowForwardSVG } from "../../Icons/UI/arrow_forward.svg";
import { ReactComponent as PartnersSVG } from "../../Icons/Images/preceram-partners.svg";
import { ReactComponent as DropDownSVG } from "../../Icons/UI/arrow_drop_down.svg";
import { ReactComponent as ENFlagSVG } from "../../Icons/Flags/GB.svg";
import { ReactComponent as PTFlagSVG } from "../../Icons/Flags/PT.svg";
import { ReactComponent as ESFlagSVG } from "../../Icons/Flags/ES.svg";
import { ReactComponent as FRFlagSVG } from "../../Icons/Flags/FR.svg";
import { Configs, JsonLanguageObject } from "../../Types/types";
import { Context, ExplorerState } from "../Explorer/explorer";
import "./contactPage.scss";
import GA from "../../GA/ga";

const ContactPage: FunctionComponent = () => {
  const [state, setState]: [ExplorerState, CallableFunction] =
    useContext(Context);

  const [langMenu, toggleLangMenu] = useState<boolean>(false);

  const [sendEnable, enableSend] = useState<boolean>(false);

  const [internetMessage, showInternetMessage] = useState<boolean>(false);

  const configs: Configs = configsJson;

  const translations = require(`../../Language/${state.language.languageShort}.json`);

  const formelement = useRef<HTMLFormElement>(null);
  const namefull = useRef<boolean>(false);
  const nameinput = useRef<HTMLInputElement>(null);
  const emailfull = useRef<boolean>(false);
  const emailinput = useRef<HTMLInputElement>(null);
  const messagefull = useRef<boolean>(false);
  const messageinput = useRef<HTMLTextAreaElement>(null);

  const description = configs.contactpage.description;

  const thanks = configs.contactpage.thanks;
  const visitLabel = configs.contactpage.visitLabel;

  let startSpace = state.data.scenes[0].id;
  let type = "360";
  if (state.data.settings.navBar.panoSequence.length > 0) {
    startSpace = state.data.settings.navBar.panoSequence[0].pano;
    type = state.data.settings.navBar.panoSequence[0].type;
  }
  const restartVisit = () => {
    setState({ ...state, contactPage: false, spaceId: startSpace, type: type });
  };

  const visitWebsite = () => {
    if (navigator.onLine) {
      window.open(state.language[configs.contactpage.visitUrl]);
    } else {
      showInternetMessage(true);
      setTimeout(() => {
        showInternetMessage(false);
      }, 3000);
    }
  };

  const validateMessage = () => {
    const enabled =
      namefull.current && emailfull.current && messagefull.current;
    enableSend(enabled);
  };

  const fulfillName = (newValue: string) => {
    if (newValue) {
      namefull.current = true;
    } else {
      namefull.current = false;
    }
    GA.sendEvent(`Fill_Name`, {});
    validateMessage();
  };

  const fulfillEmail = (newValue: string) => {
    if (newValue) {
      emailfull.current = true;
    } else {
      emailfull.current = false;
    }
    GA.sendEvent(`Fill_Email`, {});
    validateMessage();
  };

  const fulfillMessage = (newValue: string) => {
    if (newValue) {
      messagefull.current = true;
    } else {
      messagefull.current = false;
    }
    GA.sendEvent(`Fill_Message`, {});
    validateMessage();
  };

  const closedClass = state.contactPage ? "" : " closed";

  const changeLanguage = (
    newLanguage: JsonLanguageObject,
    e?: React.MouseEvent<HTMLDivElement>
  ) => {
    e?.stopPropagation();
    setState({ ...state, language: newLanguage });
    toggleLangMenu(false);
  };

  const languages = configs.multilanguage.languages.map((l, index) => {
    try {
      const languageJson: JsonLanguageObject = require(`../../Data/${l}.json`);
      let flag = <PTFlagSVG className="flag" />;
      switch (l) {
        case "en":
          flag = <ENFlagSVG className="flag" />;
          break;

        case "pt":
          flag = <PTFlagSVG className="flag" />;
          break;

        case "es":
          flag = <ESFlagSVG className="flag" />;
          break;

        case "fr":
          flag = <FRFlagSVG className="flag" />;
          break;
      }

      return (
        <div
          key={`language-selector-${index}`}
          className="lang-item"
          onClick={(e) => changeLanguage(languageJson, e)}
        >
          <span>{languageJson.language}</span>
          {flag}
        </div>
      );
    } catch (e) {
      console.warn(`${l}.json language file does not exist on Data folder`);
      return (
        <div key={`language-selector-${index}`} className="language">
          <div className="label"></div>
          <div className="flag"></div>
        </div>
      );
    }
  });

  const send = (
    name: string,
    from: string,
    message: string,
    notes?: string
  ) => {
    fetch("https://sendmail.alive.vision/sendmail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        to: "geral@grupopreceram.pt ",
        replyTo: from,
        email: "geral@3decide.com",
        name: name,
        subject: "[Preceram] Email de contacto",
        mailSubject: "[Preceram] Email de contacto",
        message: message,
        notes: notes ? notes : "",
        tid: "d-fbd3a7b7b07345e9b62c8a23dcb3317f",
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Something went wrong on api server!");
        }
      })
      .then((response) => {
        if (formelement.current) {
          formelement.current.reset();
          GA.sendEvent(`Sent_Email`, {});
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const langMenuClass = langMenu ? "menu open" : "menu";

  const sendEnabledClass = sendEnable ? " enabled" : "";

  return (
    <div
      className={"contact-page" + closedClass}
      onClick={() => langMenu && toggleLangMenu(false)}
    >
      <img
        className="background"
        src={state.language[configs.contactpage.background]}
        alt=""
      ></img>
      <div className="focus"></div>
      <div className="scrollable-container">
        <div className="scroll-canvas"></div>
        <img
          className="contact-logo"
          src={state.language[configs.contactpage.logo]}
          alt=""
        ></img>
        <div
          className="language-selector"
          onClick={() => !langMenu && toggleLangMenu(true)}
        >
          <div className="lang">{state.language.languageShort}</div>
          <DropDownSVG />
          <div className={langMenuClass}>{languages}</div>
        </div>
        <div className="content-container">
          <div className="description">{state.language[description]}</div>
          <div
            className="restart btn"
            onClick={() => {
              restartVisit();
              const langPrefix = state.language.languageShort.toUpperCase();
              GA.sendEvent(`${langPrefix}_Click_Restart_Visit`, {});
            }}
          >
            <div className="label">{translations.restartTour}</div>
            <ArrowForwardSVG />
          </div>
          <div
            className="visit btn"
            onClick={() => {
              visitWebsite();
              const langPrefix = state.language.languageShort.toUpperCase();
              GA.sendEvent(`${langPrefix}_Click_Visit_Website`, {});
            }}
          >
            <div className="label">{state.language[visitLabel]}</div>
            <ArrowForwardSVG />
          </div>
        </div>
        <div className="thanks">{state.language[thanks]}</div>
        <form
          ref={formelement}
          className="form"
          onSubmit={(e) => {
            e.preventDefault();
            if (navigator.onLine) {
              const name = nameinput.current ? nameinput.current.value : "";
              const email = emailinput.current ? emailinput.current.value : "";
              const message = messageinput.current
                ? messageinput.current.value
                : "";
              send(name, email, message);
            } else {
              showInternetMessage(true);
              setTimeout(() => {
                showInternetMessage(false);
              }, 3000);
            }
          }}
        >
          <div className="input-field">
            <input
              ref={nameinput}
              type={"text"}
              placeholder=" "
              onChange={(e) => fulfillName(e.target.value)}
            ></input>
            <div className="placeholder">{translations.nameFormLabel}</div>
          </div>
          <div className="input-field">
            <input
              ref={emailinput}
              type={"email"}
              placeholder=" "
              onChange={(e) => fulfillEmail(e.target.value)}
            ></input>
            <div className="placeholder">{translations.emailFormLabel}</div>
          </div>
          <textarea
            ref={messageinput}
            placeholder={translations.messageFormLabel}
            onChange={(e) => fulfillMessage(e.target.value)}
          ></textarea>
          <input
            className={"submit-button" + sendEnabledClass}
            type={"submit"}
            value={translations.sendFormLabel}
          ></input>
        </form>
        <PartnersSVG className="partners" />
        <AVPoweredSVG className="powered-by" />
      </div>
      <span className={"no-internet" + (internetMessage ? " show" : "")}>
        {translations.noInternetMessage}
      </span>
    </div>
  );
};

export default ContactPage;
