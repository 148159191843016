import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { PromoPage } from "../../../Types/types";
import { Context, ExplorerState } from "../../Explorer/explorer";
import { ReactComponent as ArrowForwardSVG } from "../../../Icons/UI/arrow_forward.svg";
import "./preceram.template.scss";
import GA from "../../../GA/ga";

interface PreceramPromoPageProps {
  advance: CallableFunction;
  enabledClass: string;
  promoData: PreceramPromoPageData;
}

interface PreceramPromoPageData extends PromoPage {
  title: string;
  description: string;
  resourceButtonLabel: string;
  resourceUrl: string;
  features: {
    icon: string;
    label: string;
  }[];
  gallery: string[];
  characteristics: {
    icon: string;
    label: string;
  }[];
}

const PreceramPromoPage: FunctionComponent<PreceramPromoPageProps> = ({
  advance,
  enabledClass,
  promoData,
}) => {
  const [state]: [ExplorerState, CallableFunction] = useContext(Context);

  const [selectedImage, setSelected] = useState<number>(0);

  const timer = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const mobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  useEffect(() => {
    setTimeout(() => {
      const next = (selectedImage + 1) % promoData.gallery.length;
      setSelected(next);
    }, 5000);
  }, [selectedImage, promoData.gallery]);

  useEffect(() => {
    if (enabledClass) {
      if (!timer.current) {
        timer.current = setTimeout(() => {
          advance();
        }, 15000);
      }
    } else {
      if (timer.current) {
        clearInterval(timer.current);
        timer.current = undefined;
      }
    }
  }, [enabledClass, advance]);

  const features = promoData.features.map((f, index) => {
    return (
      <div key={`feat-${index}-key`} className="feature">
        <img src={f.icon} alt=""></img>
        <div className="label">{state.language[f.label]}</div>
      </div>
    );
  });

  const gallery = promoData.gallery.map((g, index) => {
    let positionClass =
      selectedImage === index
        ? " selected"
        : selectedImage === (index + 1) % promoData.gallery.length
        ? " previous"
        : " next";

    return (
      <div
        key={`gallery-${index}-key`}
        className={"image-container" + positionClass}
      >
        <img src={g} alt=""></img>
      </div>
    );
  });

  const characteristics = promoData.characteristics.map((c, index) => {
    return (
      <div key={`char-${index}-key`} className="characteristic">
        <img src={c.icon} alt=""></img>
        <div className="label">{state.language[c.label]}</div>
      </div>
    );
  });

  return (
    <div className={"preceram-promo-page" + enabledClass}>
      <div className="promo-info">
        <div className="title">{state.language[promoData.title]}</div>
        <div className="description">
          {state.language[promoData.description]}
        </div>
        {mobile && <div className="features">{features}</div>}
        <div
          className="resource-button"
          onClick={() => {
            window.open(state.language[promoData.resourceUrl]);
            const langPrefix = state.language.languageShort.toUpperCase();
            GA.sendEvent(`${langPrefix}_Download_Pdf`, {});
          }}
        >
          <div className="label">
            {state.language[promoData.resourceButtonLabel]}
          </div>
          <ArrowForwardSVG />
        </div>
        {!mobile && <div className="features">{features}</div>}
      </div>
      <div className="promo-gallery">
        <div className="gallery">{gallery}</div>
        <div className="characteristics">{characteristics}</div>
      </div>
    </div>
  );
};

export default PreceramPromoPage;
