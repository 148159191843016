import React, { FunctionComponent, useContext, useState } from "react";
import { Context, ExplorerState } from "../../Explorer/explorer";
import configsJson from "../../../configs.json";
import {
  Configs,
  JsonLanguageObject,
  TranslationObject,
} from "../../../Types/types";
import { ReactComponent as AVPoweredSVG } from "../../../Icons/Images/alive-vision.svg";
import { ReactComponent as PartnersSVG } from "../../../Icons/Images/preceram-partners.svg";
import { ReactComponent as ArrowForwardSVG } from "../../../Icons/UI/arrow_forward.svg";
import { ReactComponent as DropDownSVG } from "../../../Icons/UI/arrow_drop_down.svg";
import { ReactComponent as ENFlagSVG } from "../../../Icons/Flags/GB.svg";
import { ReactComponent as PTFlagSVG } from "../../../Icons/Flags/PT.svg";
import { ReactComponent as ESFlagSVG } from "../../../Icons/Flags/ES.svg";
import { ReactComponent as FRFlagSVG } from "../../../Icons/Flags/FR.svg";
import "./preceram.scss";
import { MarzipanoData } from "../../../Types/marzipano.types";
import GA from "../../../GA/ga";

const LandingPage: FunctionComponent = () => {
  const [state, setState]: [ExplorerState, CallableFunction] =
    useContext(Context);

  const configs: Configs = configsJson;

  const [languageMenuOpen, openLanguageMenu] = useState<boolean>(false);

  const title = configs.landingpage.title;
  const description = configs.landingpage.description;

  const closedClass = state.landingPage ? "" : " closed";

  const data: MarzipanoData = require(`../../../Data/${configs.landingpage.data[0].data}`);

  const changeLanguage = (newLanguage: JsonLanguageObject) => {
    setState({ ...state, language: newLanguage });
  };

  const languages = configs.multilanguage.languages.map((l, index) => {
    try {
      const languageJson: JsonLanguageObject = require(`../../../Data/${l}.json`);
      let flag = <PTFlagSVG className="flag" />;
      switch (l) {
        case "en":
          flag = <ENFlagSVG className="flag" />;
          break;

        case "pt":
          flag = <PTFlagSVG className="flag" />;
          break;

        case "es":
          flag = <ESFlagSVG className="flag" />;
          break;

        case "fr":
          flag = <FRFlagSVG className="flag" />;
          break;
      }

      return (
        <div
          key={`language-selector-${index}`}
          className="language"
          onClick={() => changeLanguage(languageJson)}
        >
          <div className="label">{languageJson.language}</div>
          <div className="flag">{flag}</div>
        </div>
      );
    } catch (e) {
      console.warn(`${l}.json language file does not exist on Data folder`);
      return (
        <div key={`language-selector-${index}`} className="language">
          <div className="label"></div>
          <div className="flag"></div>
        </div>
      );
    }
  });

  const openMenuClass = languageMenuOpen ? " open" : "";

  const translations: TranslationObject = require(`../../../Language/${state.language.languageShort}.json`);

  const underlineVideoClick = () => {
    const langPrefix = state.language.languageShort.toUpperCase();
    GA.sendEvent(`${langPrefix}_Click_Video_Link`, {});
    window.open(state.language[configs.landingpage.videoURL]);
  };
  const clickHere: (string | JSX.Element)[] = translations.clickHereVideo.split(
    translations.underlineSeeHere
  );
  clickHere.splice(
    1,
    0,
    <u key="underline-see-here" onClick={() => underlineVideoClick()}>
      {translations.underlineSeeHere}
    </u>
  );

  return (
    <div className={"preceram-landing-page-desktop" + closedClass}>
      <img
        className="background"
        src={state.language[configs.landingpage.background]}
        alt=""
      ></img>
      <div className="focus"></div>
      <img
        className="logo"
        src={state.language[configs.landingpage.logo]}
        alt=""
      ></img>
      <div
        className="preceram-landing-page-language-selector"
        onClick={() => openLanguageMenu(!languageMenuOpen)}
      >
        <div className="selected">{state.language.languageShort}</div>
        <DropDownSVG />
        <div className={"preceram-languages-container" + openMenuClass}>
          {languages}
        </div>
      </div>
      <div className="content-container">
        <div className="title">{state.language[title]}</div>
        <div className="description">{state.language[description]}</div>
        <div className="start-btn-container">
          <div
            className="start-btn"
            onClick={() => {
              const newData = { ...data };
              if (newData.settings.advancedAutoplay) {
                newData.settings.advancedAutoplay.enabled = false;
              }
              const langPrefix = state.language.languageShort.toUpperCase();
              GA.sendEvent(`${langPrefix}_Click_Interactive`, {});
              setState({
                ...state,
                landingPage: false,
                spaceId: configs.landingpage.data[0].scene,
                type: configs.landingpage.data[0].type,
                data: newData,
              });
            }}
          >
            <div className="start-label">{translations.startVisit360}</div>
            <ArrowForwardSVG />
          </div>
          <div
            className="start-btn alt"
            onClick={() => {
              const newData = { ...data };
              if (newData.settings.advancedAutoplay) {
                newData.settings.advancedAutoplay.enabled = true;
              }
              const langPrefix = state.language.languageShort.toUpperCase();
              GA.sendEvent(`${langPrefix}_Click_Guided`, {});
              setState({
                ...state,
                landingPage: false,
                spaceId: configs.landingpage.data[0].scene,
                type: configs.landingpage.data[0].type,
                data: newData,
                advancedAutoplay: true,
              });
            }}
          >
            <div className="start-label">{translations.startVisitGuided}</div>
            <ArrowForwardSVG />
          </div>
        </div>
        <div className="watch-video-here">{clickHere}</div>
      </div>
      <PartnersSVG className="partners" />
      <AVPoweredSVG className="powered-by" />
    </div>
  );
};

export default LandingPage;
