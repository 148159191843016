import React from "react";
import ReactDOM from "react-dom";
//import Explorer from "./Desktop/Explorer/explorer";
import "./index.scss";
import ExplorerMobile from "./Mobile/Explorer/explorer";

if (
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
) {
  ReactDOM.render(
    <React.StrictMode>
      <ExplorerMobile />
    </React.StrictMode>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <ExplorerMobile />
    </React.StrictMode>,
    document.getElementById("root")
  );
}
