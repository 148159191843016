import React, { FunctionComponent, useContext, useState } from "react";
import configsJson from "../../../../configs.json";
import {
  Configs,
  Guideline,
  JsonLanguageObject,
} from "../../../../Types/types";
import { Context, ExplorerState } from "../../../Explorer/explorer";
import { ReactComponent as DropDownSVG } from "../../../../Icons/UI/arrow_drop_down.svg";
import { ReactComponent as ENFlagSVG } from "../../../../Icons/Flags/GB.svg";
import { ReactComponent as PTFlagSVG } from "../../../../Icons/Flags/PT.svg";
import { ReactComponent as ESFlagSVG } from "../../../../Icons/Flags/ES.svg";
import { ReactComponent as FRFlagSVG } from "../../../../Icons/Flags/FR.svg";
import "./home.scss";
import GA from "../../../../GA/ga";

const Home: FunctionComponent = () => {
  const [state, setState]: [ExplorerState, CallableFunction] =
    useContext(Context);

  const configs: Configs = configsJson;

  const [languageMenuOpen, openLanguageMenu] = useState<boolean>(false);

  const returnToLandingPage = () => {
    const advancedAutoplayState = state.data.settings.advancedAutoplay.enabled
      ? true
      : false;

    let newSpaceId = state.spaceId;
    let type = state.type;

    if (state.data.settings.advancedAutoplay?.enabled) {
      const autoplayGuideline: Guideline[] = require(`../../../../Data/${state.data.settings.advancedAutoplay.guidelines}`);
      newSpaceId = autoplayGuideline[0].id;
      type = autoplayGuideline[0].type;
    }

    setState({
      ...state,
      landingPage: true,
      autoplay: false,
      advancedAutoplay: advancedAutoplayState,
      spaceId: newSpaceId,
      type: type,
    });

    const langPrefix = state.language.languageShort.toUpperCase();
    GA.sendEvent(`${langPrefix}_Click_Home`, {});
  };

  const changeLanguage = (newLanguage: JsonLanguageObject) => {
    setState({ ...state, language: newLanguage, advancedAutoplay: false });
  };

  const languages = configs.multilanguage.languages.map((l, index) => {
    try {
      const languageJson: JsonLanguageObject = require(`../../../../Data/${l}.json`);
      let flag = <PTFlagSVG className="flag" />;
      switch (l) {
        case "en":
          flag = <ENFlagSVG className="flag" />;
          break;

        case "pt":
          flag = <PTFlagSVG className="flag" />;
          break;

        case "es":
          flag = <ESFlagSVG className="flag" />;
          break;

        case "fr":
          flag = <FRFlagSVG className="flag" />;
          break;
      }

      return (
        <div
          key={`language-selector-${index}`}
          className="language"
          onClick={() => changeLanguage(languageJson)}
        >
          <div className="label">{languageJson.language}</div>
          <div className="flag">{flag}</div>
        </div>
      );
    } catch (e) {
      console.warn(`${l}.json language file does not exist on Data folder`);
      return (
        <div key={`language-selector-${index}`} className="language">
          <div className="label"></div>
          <div className="flag"></div>
        </div>
      );
    }
  });

  const openMenuClass = languageMenuOpen ? " open" : "";

  const searchParams = new URLSearchParams(window.location.search);
  let hiddenLanguageSelectorClass = "";
  if (searchParams.get("controls") === "false") {
    hiddenLanguageSelectorClass = " hidden";
  }

  return (
    <>
      <div className="preceram-home-desktop">
        <img
          src={state.language[configs.logo]}
          alt=""
          onClick={() => {
            returnToLandingPage();
          }}
        ></img>
        <div
          className={"preceram-language-selector" + hiddenLanguageSelectorClass}
          onClick={() => openLanguageMenu(!languageMenuOpen)}
        >
          <div className="selected">{state.language.languageShort}</div>
          <DropDownSVG />
          <div className={"preceram-languages-container" + openMenuClass}>
            {languages}
          </div>
        </div>
      </div>
      <div
        className={"close-focus" + openMenuClass}
        onClick={() => openLanguageMenu(false)}
      ></div>
    </>
  );
};

export default Home;
