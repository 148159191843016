import React, { FunctionComponent, useContext, useState } from "react";
import { ModalHotspot } from "../../../Types/hotspots.types";
import { ReactComponent as CloseSVG } from "../../../Icons/close.svg";
import { ReactComponent as MoreInfoSVG } from "../../../Icons/UI/info.svg";
import "./preceram.template.scss";
import { Context, ExplorerState } from "../../Explorer/explorer";

interface ModalHotspotPreceramTemplate extends ModalHotspot {
  description: string;
  image: string;
}

interface PreceramTemplateProps {
  data: ModalHotspotPreceramTemplate;
  activeClass: string;
  deactivate: CallableFunction;
}

const PreceramTemplate: FunctionComponent<PreceramTemplateProps> = ({
  data,
  activeClass,
  deactivate,
}) => {
  const [state]: [ExplorerState, CallableFunction] = useContext(Context);

  const [drawer, toggleDrawer] = useState<boolean>(false);

  return (
    <div className={"preceram-template" + activeClass}>
      <div
        className="focus"
        onClick={() => {
          deactivate();
          toggleDrawer(false);
        }}
      ></div>
      <div className="modal-container">
        <CloseSVG
          onClick={() => {
            deactivate();
            toggleDrawer(false);
          }}
        />
        <div
          className="image"
          onClick={() => {
            drawer && toggleDrawer(false);
          }}
        >
          <img src={state.language[data.image]} alt=""></img>
        </div>
        <div className={drawer ? "info" : "info inactive"}>
          <div className="title">{state.language[data.title]}</div>
          <div className="text">{state.language[data.description]}</div>
        </div>
        <MoreInfoSVG
          className={drawer ? "more-info inactive" : "more-info"}
          onClick={() => toggleDrawer(true)}
        />
      </div>
    </div>
  );
};

export default PreceramTemplate;
