import React, { FunctionComponent, useContext, useEffect, useRef } from "react";
import { Context, ExplorerState } from "../Explorer/explorer";

interface SceneAudioProps {
  scene: string;
  audio: {
    voiceover: string;
  };
}

const SceneAudio: FunctionComponent<SceneAudioProps> = ({ scene, audio }) => {
  const [state]: [ExplorerState, CallableFunction] = useContext(Context);

  const mobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  //useState for mute

  const audioVORef = useRef<HTMLAudioElement>(null);
  const audioAMBRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (state.spaceId === scene && !state.landingPage && !state.contactPage) {
      if (audioVORef.current) {
        audioVORef.current.currentTime = 0;
        audioVORef.current.volume = 1;
        // audioVORef.current.load();
        audioVORef.current.play();
      }
      if (audioAMBRef.current) {
        audioAMBRef.current.currentTime = 0;
        audioAMBRef.current.volume = 1;
        // audioAMBRef.current.load();
        audioAMBRef.current.play();
      }
    } else {
      audioVORef.current && audioVORef.current.pause();
      audioAMBRef.current && audioAMBRef.current.pause();
    }
  }, [state.spaceId, state.landingPage, state.contactPage, scene]);

  useEffect(() => {
    if (audioVORef.current) audioVORef.current.muted = !state.sound;
    if (audioAMBRef.current) audioAMBRef.current.muted = !state.sound;
  }, [state.sound]);

  // useEffect(() => {
  //   if (mobile) {
  //     if (audioVORef.current) {
  //       audioVORef.current.onended = () => {
  //         audioAMBRef.current && audioAMBRef.current.load();
  //         audioAMBRef.current && audioAMBRef.current.play();
  //       };
  //     }
  //   }
  // });

  const dataScene = state.data.scenes.find((s) => s.id === scene);

  return (
    <>
      {audio.voiceover && (
        <audio
          ref={audioVORef}
          src={state.language[audio.voiceover]}
          preload="auto"
        />
      )}
      {mobile && dataScene && dataScene.resonanceAudio?.audio.source && (
        <audio
          ref={audioAMBRef}
          src={dataScene.resonanceAudio.audio.source}
          preload="auto"
          loop
        />
      )}
    </>
  );
};

export default SceneAudio;
