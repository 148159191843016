import React, { FunctionComponent, useContext, useEffect, useRef } from "react";
import { Guideline } from "../../Types/types";
import { Context, ExplorerState } from "../Explorer/explorer";
import "./videoMedia.scss";

const VideoMedia: FunctionComponent = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const secondaryAudioRef = useRef<HTMLAudioElement>(null);

  const [state, setState]: [ExplorerState, CallableFunction] =
    useContext(Context);

  useEffect(() => {
    const doneCallback = () => {
      const panoSeq = state.data.settings.navBar.panoSequence;
      const panoSequenceAvailable = panoSeq.length > 0;

      let currentSceneIdx = panoSequenceAvailable
        ? panoSeq.findIndex((p) => p.pano === state.spaceId)
        : state.data.scenes.findIndex((s) => s.id === state.spaceId);

      if (panoSequenceAvailable) {
        if (currentSceneIdx + 1 < panoSeq.length) {
          const nextSceneId = panoSeq[currentSceneIdx + 1].pano;
          const nextType = panoSeq[currentSceneIdx + 1].type;
          setState({ ...state, spaceId: nextSceneId, type: nextType });
        } else {
          setState({ ...state, contactPage: true, autoplay: false });
        }
      } else {
        if (currentSceneIdx + 1 < state.data.scenes.length) {
          const nextSceneId = state.data.scenes[currentSceneIdx + 1].id;
          const nextType = "360";
          setState({ ...state, spaceId: nextSceneId, type: nextType });
        } else {
          setState({ ...state, contactPage: true, autoplay: false });
        }
      }
    };

    const doneAutoplayCallback = () => {
      if (
        state.data.settings.advancedAutoplay?.enabled &&
        state.type === "video"
      ) {
        const guide: Guideline[] = require(`../../Data/${state.data.settings.advancedAutoplay.guidelines}`);
        const idx = guide.findIndex((g) => g.id === state.spaceId);
        if (idx >= 0) {
          if (guide.length > idx + 1) {
            setState({
              ...state,
              spaceId: guide[idx + 1].id,
              type: guide[idx + 1].type,
            });
          } else {
            setState({
              ...state,
              landingPage: true,
              spaceId: guide[0].id,
              type: guide[0].type,
            });
          }
        }
      }
    };

    if (videoRef.current) {
      videoRef.current.onended = () => {
        if (state.data.settings.advancedAutoplay?.enabled) {
          doneAutoplayCallback();
        } else {
          doneCallback();
        }
      };
    }
  }, [state, setState]);

  useEffect(() => {
    if (videoRef.current) {
      if (state.type === "video" && !state.landingPage && !state.contactPage) {
        if (
          !state.advancedAutoplay &&
          state.data.settings.advancedAutoplay?.enabled
        ) {
          videoRef.current.pause();
          secondaryAudioRef.current && secondaryAudioRef.current.pause();
        } else {
          videoRef.current.play();
          secondaryAudioRef.current && secondaryAudioRef.current.play();
        }
      }
    }
  }, [
    state.type,
    state.landingPage,
    state.contactPage,
    state.advancedAutoplay,
    state.data,
  ]);

  useEffect(() => {
    if (videoRef.current) videoRef.current.muted = !state.sound;
    if (secondaryAudioRef.current)
      secondaryAudioRef.current.muted = !state.sound;
  }, [state.sound]);

  /*useEffect(() => {
    if (videoRef.current && state.data.settings.advancedAutoplay?.enabled) {
      if (state.advancedAutoplay) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [state.advancedAutoplay, state.data]);*/

  const activeClass = state.type === "video" ? " active" : "";

  const onContainerClick = () => {
    //should the video stop on click?
    if (videoRef.current) {
      if (videoRef.current.paused) {
        //videoRef.current.play();
      } else {
        //videoRef.current.pause();
      }
    }
  };

  let secondaryAudioSrc = "";
  if (state.data.settings.advancedAutoplay?.enabled && state.type === "video") {
    const guide: Guideline[] = require(`../../Data/${state.data.settings.advancedAutoplay.guidelines}`);
    const idx = guide.findIndex((g) => g.id === state.spaceId);
    if (idx >= 0) {
      const media = guide[idx].media;
      const src = media ? media.src : "";
      secondaryAudioSrc = src;
    }
  }

  return (
    <div
      className={"video-media-container" + activeClass}
      onClick={() => {
        onContainerClick();
      }}
    >
      {state.type === "video" && !state.landingPage && !state.contactPage && (
        <>
          <video
            ref={videoRef}
            src={state.language[state.spaceId]}
            playsInline={true}
            muted={!state.sound}
          />
          {state.data.settings.advancedAutoplay?.enabled &&
            secondaryAudioSrc && (
              <audio ref={secondaryAudioRef} src={secondaryAudioSrc}></audio>
            )}
        </>
      )}
    </div>
  );
};

export default VideoMedia;
