import React, {
  createContext,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from "react";
import { Socket, io } from "socket.io-client";
import { HotspotElement, MarzipanoData } from "../../Types/marzipano.types";
import Viewer360 from "../Marzipano/viewer";
import configsJson from "../../configs.json";
import { Configs, JsonLanguageObject } from "../../Types/types";
import HotspotContainer from "../Hotspots/hotspotContainer";
import ContactPage from "../ContactPage/contactPage";
import ContactPageDesktop from "../ContactPage/desktop/contactPage";
import Marzipano from "../Marzipano/marzipano";
import PromoPageViewer from "../PromoPage/promopage";
import ResonanceAudioElement, {
  ResonanceAudioHotspot,
} from "../ResonanceAudio/resonanceAudio";
import VideoMedia from "../VideoMedia/videoMedia";
import AdvancedAutoplay from "../AdvancedAutoplay/advancedAutoplay";
import AudioContainer from "../Audio/audio";
import IndicationPanel from "../IndicationPanel/indicationPanel";
import GA from "../../GA/ga";
import enJson from "../../Data/en.json";

export interface ExplorerState {
  circuits: MarzipanoData[];
  data: MarzipanoData;
  language: JsonLanguageObject;
  hotspots: HotspotElement[];
  resonanceAudios: ResonanceAudioHotspot[];
  spaceId: string;
  type: string;
  autoplay: boolean;
  advancedAutoplay: boolean;
  hiddenInterface: boolean;
  landingPage: boolean;
  contactPage: boolean;
  sound: boolean;
  business: BusinessState;
}

export interface BusinessState {
  active: boolean;
  admin: string;
  presentation: boolean;
  users: { id: string; name: string; controlling: boolean }[];
}

export const Context = createContext<any>(undefined);
export const SocketContext = createContext<any>(undefined);
export const SFXContext = createContext<any>(undefined);

const ExplorerMobile: FunctionComponent = () => {
  const configs: Configs = configsJson;

  const circuits: MarzipanoData[] = configs.circuits.map((c) => {
    const cData = require(`../../Data/${c}`);
    return cData;
  });

  const mobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const [autoplayDoneFlag, autoplayDone] = useState<boolean>(false);

  const firstRender = useRef<boolean>(true);

  const sfxRef = useRef<HTMLAudioElement>(null);

  /* Get all hotspots from each scene from each circuit and push it to a Hotspot array */
  const tmpHotspots: HotspotElement[] = [];
  const tmpRa: ResonanceAudioHotspot[] = []; // Get all audios from each scene
  circuits.forEach((c) => {
    c.scenes.forEach((s) => {
      if (s.resonanceAudio) {
        const id = `${c.name}-${s.id}-resonance-audio}`;
        const r = (
          <ResonanceAudioElement
            key={id}
            scene={s.id}
            resonanceAudioParams={s.resonanceAudio}
          />
        );
        tmpRa.push({
          id: id,
          sceneId: s.id,
          resonanceAudio: r,
        });
      }

      s.infoHotspots.forEach((info, index) => {
        let id = `${c.name}-${s.id}-info-${index}`;
        id = id.toLowerCase();
        const infoEl = {
          id: id,
          data: info,
          active: false,
          hover: false,
          /*element: (
            <InfoHotspotElement key={id} id={id} data={info} scene={s.id} />
          ),*/
          type: "info",
          sceneId: s.id,
        };
        tmpHotspots.push(infoEl);
      });

      s.linkHotspots.forEach((link, index) => {
        let id = `${c.name}-${s.id}-link-${index}`;
        id = id.toLowerCase();
        const linkEl = {
          id: id,
          data: link,
          active: false,
          hover: false,
          /*element: (
            <LinkHotspotElement key={id} id={id} data={link} scene={s.id} />
          ),*/
          type: "link",
          sceneId: s.id,
        };
        tmpHotspots.push(linkEl);
      });

      s.modalHotspots?.forEach((modal, index) => {
        let id = `${c.name}-${s.id}-modal-${index}`;
        id = id.toLowerCase();
        const modalEl = {
          id: id,
          data: modal,
          active: false,
          hover: false,
          /*element: (
            <ModalHotspotElement key={id} id={id} data={modal} scene={s.id} />
          ),*/
          type: "modal",
          sceneId: s.id,
        };
        tmpHotspots.push(modalEl);
      });

      s.mediaHotspots?.forEach((media, index) => {
        let id = `${c.name}-${s.id}-media-${index}`;
        id = id.toLowerCase();
        const mediaEl = {
          id: id,
          data: media,
          active: false,
          hover: false,
          /*element: (
            <MediaHotspotElement key={id} id={id} data={media} scene={s.id} />
          ),**/
          type: "media",
          sceneId: s.id,
        };
        tmpHotspots.push(mediaEl);
      });
    });
  });

  let prefLanguage = navigator.languages.find((l) => {
    const prefix = l.slice(0, 2).toLowerCase();
    return configs.multilanguage.languages.includes(prefix);
  });
  prefLanguage = prefLanguage
    ? prefLanguage.slice(0, 2).toLowerCase()
    : configs.multilanguage.default;

  const defaultLanguage = require(`../../Data/${prefLanguage}.json`);

  const [state, setState] = useState<ExplorerState>({
    circuits: circuits,
    data: circuits[0],
    language: defaultLanguage,
    hotspots: tmpHotspots,
    resonanceAudios: tmpRa,
    spaceId: configs.landingpage.data[0].scene,
    type: configs.landingpage.data[0].type,
    autoplay: false,
    advancedAutoplay: circuits[0].settings.advancedAutoplay?.enabled
      ? true
      : false,
    hiddenInterface: false,
    landingPage: true,
    contactPage: false,
    sound: true,
    business: {
      active: false,
      admin: "",
      presentation: false,
      users: [],
    },
  });

  const user = useRef<{ id: string; name: string }>({ id: "", name: "" });

  const [socket, initSocket] = useState<Socket | undefined>(undefined);

  const [marzipanoLoaded, loadMarzipano] = useState<boolean>(false);

  /**
   * Check if marzipano is loaded to render the other components
   */
  useEffect(() => {
    const interval = setInterval(() => {
      if (Marzipano.getView()) {
        loadMarzipano(true);
        clearInterval(interval);
      }
    }, 100);
  }, []);

  /**
   * Set viewport height based on actual window height,
   * for the cases where the url address bar from browsers shortens the height
   * (usually on mobile devices)
   */
  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
    window.addEventListener("orientationchange", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }, []);

  /**
   * Configure Google Analytics
   */
  useEffect(() => {
    console.log(process.env);
    if (process.env.REACT_APP_GA_TAG) {
      navigator.onLine && GA.config(process.env.REACT_APP_GA_TAG);
    }
  }, []);

  /**
   * Send GA event evry time a space changes or goes to the landing/contact page
   */
  useEffect(() => {
    const langPrefix = state.language.languageShort.toUpperCase();
    if (state.landingPage) {
      GA.pageView("/", `${langPrefix}_Landing_Page`);
    } else {
      if (state.contactPage) {
        GA.pageView("/contact", `${langPrefix}_Contact_Page`);
      } else {
        if (state.type === "video") {
          const videoN =
            state.data.settings.navBar.panoSequence
              .filter((p) => p.type === state.type)
              .findIndex((p) => p.pano === state.spaceId) + 1;
          const video = state.data.settings.navBar.panoSequence.find(
            (p) => p.pano === state.spaceId
          );
          if (videoN > 0 && video) {
            const videoName = video.tag ? video.tag : `n.${videoN}`;
            GA.pageView(
              `/video/${videoName}`,
              `${langPrefix}_Video_${videoName}`
            );
          }
        } else {
          if (state.type === "promo") {
            const promoN =
              state.data.settings.navBar.panoSequence
                .filter((p) => p.type === state.type)
                .findIndex((p) => p.pano === state.spaceId) + 1;
            const promo = state.data.settings.navBar.panoSequence.find(
              (p) => p.pano === state.spaceId
            );
            if (promoN > 0 && promo) {
              const promoName = promo.tag ? promo.tag : `n.${promoN}`;
              GA.pageView(
                `/promo/${promoName}`,
                `${langPrefix}_Promo_${promoName}`
              );
            }
          } else {
            const sceneSequence = state.data.settings.navBar.panoSequence.find(
              (s) => s.pano === state.spaceId
            );
            if (sceneSequence) {
              if (sceneSequence.tag) {
                GA.pageView(
                  `/space/${sceneSequence.tag}`,
                  `${langPrefix}_Space_${sceneSequence.tag}`
                );
              } else {
                const scene = state.data.scenes.find(
                  (s) => s.id === state.spaceId
                );
                if (scene) {
                  for (const [key, value] of Object.entries(enJson)) {
                    if (key === scene.name) {
                      GA.pageView(
                        `/space/${value}`,
                        `${langPrefix}_Space_${value}`
                      );
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }, [
    state.spaceId,
    state.data,
    state.type,
    state.language,
    state.landingPage,
    state.contactPage,
  ]);

  /**
   * Send GA event on language change
   */
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      GA.sendEvent(
        `Change_Language_${state.language.languageShort.toUpperCase()}`,
        {}
      );
    }
  }, [state.language]);

  useEffect(() => {
    if (state.business.active) {
      if (!socket) {
        const newSocket = io("http://localhost:8080", {
          query: { room: "1234", name: user.current.name },
        });
        newSocket.on("connection", ({ id }) => {
          user.current.id = id; // should get from connection payload
          newSocket.on("changeState", (newState) => {
            console.log("state was changed by socket");
            setState({ ...newState });
          });
          initSocket(newSocket);
        });
      }
    } else {
      if (socket) {
        socket.disconnect();
        initSocket(undefined);
      }
    }
  }, [state.business.active, socket]);

  useEffect(() => {
    if (socket) {
      if (!state.business.users.find((u) => u.id === user.current.id)) {
        const newUser = {
          id: user.current.id,
          name: user.current.name,
          controlling: true,
        };
        const newUsers = [...state.business.users];
        newUsers.push(newUser);
        setState({
          ...state,
          business: { ...state.business, users: newUsers },
        });
      }
    }
  }, [state, socket]);

  useEffect(() => {
    if (socket) {
      const currentUser = state.business.users?.find(
        (u) => u.id === user.current.id
      );
      if (
        state.business.active &&
        state.business.presentation &&
        currentUser &&
        currentUser.controlling
      ) {
        socket.emit("changeState", state);
      }
    }
  }, [state, socket]);

  /* When autoplay changes to true it runs a camera animation and it changes to the next panorama. 
  After changing to a new panorama, if autoplay is true, the animation continues */
  useEffect(() => {
    const doneCallback = () => {
      const panoSeq = state.data.settings.navBar.panoSequence;
      const panoSequenceAvailable = panoSeq.length > 0;

      let currentSceneIdx = panoSequenceAvailable
        ? panoSeq.findIndex((p) => p.pano === state.spaceId)
        : state.data.scenes.findIndex((s) => s.id === state.spaceId);

      if (panoSequenceAvailable) {
        if (currentSceneIdx + 1 < panoSeq.length) {
          const nextSceneId = panoSeq[currentSceneIdx + 1].pano;
          const nextType = panoSeq[currentSceneIdx + 1].type;
          setState({ ...state, spaceId: nextSceneId, type: nextType });
        } else {
          setState({ ...state, contactPage: true, autoplay: false });
        }
      } else {
        if (currentSceneIdx + 1 < state.data.scenes.length) {
          const nextSceneId = state.data.scenes[currentSceneIdx + 1].id;
          const nextType = "360";
          setState({ ...state, spaceId: nextSceneId, type: nextType });
        } else {
          setState({ ...state, contactPage: true, autoplay: false });
        }
      }
    };

    if (state.autoplay) {
      if (autoplayDoneFlag) {
        autoplayDone(false);
        doneCallback();
      } else {
        if (state.type === "360") {
          const pos = Marzipano.cameraPosition();
          Marzipano.longLookTo(
            pos.yaw + 2 * Math.PI - 0.1,
            0,
            state.data.settings.navBar.autoplay.intervalDuration,
            () => {
              autoplayDone(true);
            }
          );
        }
        if (state.type === "video") {
          /** Should procede when video finishes */
          /*setTimeout(() => {
            doneCallback();
          }, 2500);
          Logic on VideoMedia
          */
        }
        if (state.type === "promo") {
          setTimeout(() => {
            doneCallback();
          }, state.data.settings.navBar.autoplay.intervalDuration);
        }
      }
    } else {
      if (!state.data.settings.advancedAutoplay.enabled) {
        autoplayDone(false);
      }
    }
  }, [state, autoplayDoneFlag]);

  useEffect(() => {
    if (!autoplayDoneFlag) {
      Marzipano.stopMovement();
    }
  }, [autoplayDoneFlag]);

  const desktop = !mobile ? "desktop/" : "";

  const UInterface =
    require(`../UI/${configs.navBar}/${desktop}index.tsx`).default;

  const LandingPage =
    require(`../LandingPage/${desktop}${configs.landingpage.template}.tsx`).default;

  const sfxPlay = () => {
    if (sfxRef.current) {
      // sfxRef.current.load();
      sfxRef.current.play();
    }
  };

  return (
    <SocketContext.Provider value={socket}>
      <Context.Provider value={[state, setState]}>
        {marzipanoLoaded &&
          !state.landingPage &&
          !state.contactPage &&
          !mobile &&
          state.resonanceAudios.map((ra) => {
            return ra.resonanceAudio;
          })}
        <Viewer360 />
        <SFXContext.Provider value={sfxPlay}>
          <HotspotContainer />
        </SFXContext.Provider>
        <PromoPageViewer />
        <VideoMedia />
        {state.data.settings.advancedAutoplay.enabled &&
          !state.landingPage &&
          !state.contactPage &&
          marzipanoLoaded && (
            <AdvancedAutoplay settings={state.data.settings.advancedAutoplay} />
          )}
        {!state.data.settings.advancedAutoplay.enabled &&
          !state.landingPage &&
          !state.contactPage &&
          marzipanoLoaded && <AudioContainer />}
        <UInterface />
        <IndicationPanel />
        <SFXContext.Provider value={sfxPlay}>
          <LandingPage />
        </SFXContext.Provider>
        {mobile ? <ContactPage /> : <ContactPageDesktop />}
        <audio
          ref={sfxRef}
          src="./assets/audio/sfx/hotspot-sfx.mp3"
          muted={!state.sound}
          preload="auto"
        />
      </Context.Provider>
    </SocketContext.Provider>
  );
};

export default ExplorerMobile;
