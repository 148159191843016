import React, { FunctionComponent, useContext, useEffect } from "react";
import { ReactComponent as ArrowBackSVG } from "../../../../Icons/UI/preceram/arrow_back.svg";
import { ReactComponent as ArrowForwardSVG } from "../../../../Icons/UI/preceram/arrow_forward.svg";
import { ReactComponent as PlaySVG } from "../../../../Icons/UI/preceram/play_circle.svg";
import { ReactComponent as PauseSVG } from "../../../../Icons/UI/preceram/pause_circle.svg";
import { ReactComponent as StopSVG } from "../../../../Icons/UI/preceram/stop_filled.svg";
import { ReactComponent as RestartSVG } from "../../../../Icons/UI/preceram/restart.svg";
import { ReactComponent as InfoSVG } from "../../../../Icons/UI/preceram/info.svg";
import { ReactComponent as VolumeUpSVG } from "../../../../Icons/UI/preceram/volume_up.svg";
import { ReactComponent as SoundOffSVG } from "../../../../Icons/UI/preceram/sound_off.svg";
import "./guideBar.scss";
import { Context, ExplorerState } from "../../../Explorer/explorer";
import { Guideline } from "../../../../Types/types";

const GuideBar: FunctionComponent = () => {
  const [state, setState]: [ExplorerState, CallableFunction] =
    useContext(Context);

  const guide: Guideline[] = require(`../../../../Data/${state.data.settings.advancedAutoplay.guidelines}`);

  const currentIdx = guide.findIndex((g) => g.id === state.spaceId);
  const nSteps = guide.length;

  const backClass = currentIdx === 0 ? " inactive" : "";
  let forwardClass = currentIdx === guide.length - 1 ? " inactive" : "";

  /*###### Perguntar ao césar sobre a next scene ######*/
  /*const prevName = currentIdx > 0 ? guide[currentIdx - 1].label : "";
  const nextName =
    currentIdx < guide.length - 1 ? guide[currentIdx + 1].label : "";*/

  const backClick = () => {
    if (currentIdx > 0) {
      const spaceId = guide[currentIdx - 1].id;
      const type = guide[currentIdx - 1].type;
      setState({ ...state, spaceId: spaceId, type: type });
    }
  };

  const forwardClick = () => {
    if (currentIdx < guide.length - 1) {
      const spaceId = guide[currentIdx + 1].id;
      const type = guide[currentIdx + 1].type;
      setState({ ...state, spaceId: spaceId, type: type });
    }
  };

  const toggleAdvancedAutoplay = (play: boolean) => {
    setState({ ...state, advancedAutoplay: play });
  };

  const stop = () => {
    setState({
      ...state,
      landingPage: true,
      spaceId: guide[0].id,
      type: guide[0].type,
      advancedAutoplay: true,
    });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("controls") === "false") {
      document.onkeydown = (e) => {
        if (e.code === "Space") {
          setState({ ...state, advancedAutoplay: !state.advancedAutoplay });
        }
        if (e.key === "ArrowRight") {
          if (currentIdx < guide.length - 1) {
            const spaceId = guide[currentIdx + 1].id;
            const type = guide[currentIdx + 1].type;
            setState({ ...state, spaceId: spaceId, type: type });
          }
        }
        if (e.key === "ArrowLeft") {
          if (currentIdx > 0) {
            const spaceId = guide[currentIdx - 1].id;
            const type = guide[currentIdx - 1].type;
            setState({ ...state, spaceId: spaceId, type: type });
          }
        }
      };
    }
  }, [state, setState, guide, currentIdx]);

  if (!state.data.settings.advancedAutoplay?.enabled) {
    return <></>;
  }

  const scene = state.data.scenes.find((s) => state.spaceId === s.id);
  const description = scene?.description;

  let sceneName = guide[currentIdx].label
    ? guide[currentIdx].label
    : scene
    ? state.language[scene.name]
    : "";

  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.get("controls") === "false") {
    return (
      <>
        {sceneName && (
          <div className="header no-controls">
            <div className="title">{state.language[sceneName]}</div>
          </div>
        )}
        {/*description && (
          <div className={"info-container no-controls"}>
            <div className="info">{state.language[description]}</div>
          </div>
        )*/}
      </>
    );
  }

  const volumeClick = (on: boolean) => {
    setState({ ...state, sound: on });
  };

  if (state.advancedAutoplay) {
    return (
      <>
        <div className="panorama-name-container">
          <div className="name">{sceneName}</div>
          <div className="progress">{`${currentIdx + 1}/${nSteps}`}</div>
        </div>
        <div className="guide-bar">
          <PauseSVG
            className="autoplay"
            onClick={() => {
              toggleAdvancedAutoplay(false);
            }}
          />
          <div className="stop" onClick={() => stop()}>
            <StopSVG />
          </div>
        </div>
        {scene?.name && description && (
          <div className={"info-container"}>
            <div className="header">
              <InfoSVG className="white" />
              <div className="title">{sceneName}</div>
            </div>
            <div className="info">{state.language[description]}</div>
          </div>
        )}
        {state.sound ? (
          <VolumeUpSVG className="volume" onClick={() => volumeClick(false)} />
        ) : (
          <SoundOffSVG className="volume" onClick={() => volumeClick(true)} />
        )}
      </>
    );
  } else {
    return (
      <>
        <div className="panorama-name-container">
          <div className="name">{sceneName}</div>
          <div className="progress">{`${currentIdx + 1}/${nSteps}`}</div>
        </div>
        <div className="guide-bar">
          <div className={"guide-arrow back" + backClass}>
            <ArrowBackSVG className={backClass} onClick={() => backClick()} />
          </div>
          <PlaySVG
            className="autoplay"
            onClick={() => {
              toggleAdvancedAutoplay(true);
            }}
          />
          <div className={"guide-arrow forward" + forwardClass}>
            <ArrowForwardSVG
              className={forwardClass}
              onClick={() => forwardClick()}
            />
          </div>
          <div className="stop" onClick={() => stop()}>
            <RestartSVG />
          </div>
        </div>
        {scene?.name && description && (
          <div className={"info-container"}>
            <div className="header">
              <InfoSVG className="white" />
              <div className="title">{sceneName}</div>
            </div>
            <div className="info">{state.language[description]}</div>
          </div>
        )}
        {state.sound ? (
          <VolumeUpSVG className="volume" onClick={() => volumeClick(false)} />
        ) : (
          <SoundOffSVG className="volume" onClick={() => volumeClick(true)} />
        )}
      </>
    );
  }
};

export default GuideBar;
