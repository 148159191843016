import React, { FunctionComponent, useContext } from "react";
import { ModalHotspot } from "../../../../Types/hotspots.types";
import { ReactComponent as CloseSVG } from "../../../../Icons/close.svg";
import "./text.template.scss";
import { Context, ExplorerState } from "../../../Explorer/explorer";

interface ModalHotspotTextTemplate extends ModalHotspot {
  description: string;
  image: string;
}

interface TextTemplateProps {
  data: ModalHotspotTextTemplate;
  activeClass: string;
  deactivate: CallableFunction;
}

const TextTemplateDesktop: FunctionComponent<TextTemplateProps> = ({
  data,
  activeClass,
  deactivate,
}) => {
  const [state]: [ExplorerState, CallableFunction] = useContext(Context);

  return (
    <div className={"text-template-desktop" + activeClass}>
      <div className="focus" onClick={() => deactivate()}></div>
      <div className="modal-container">
        <div className="head">
          <div className="title">{state.language[data.title]}</div>
          <div className="close" onClick={() => deactivate()}>
            <CloseSVG />
          </div>
        </div>
        <div className="body">
          <div className="image">
            <img src={state.language[data.image]} alt=""></img>
          </div>
          <div className="text">{state.language[data.description]}</div>
        </div>
      </div>
    </div>
  );
};

export default TextTemplateDesktop;
