import React, { FunctionComponent } from "react";
import ControlBar from "./controlBar";

const Business: FunctionComponent = () => {
  return (
    <>
      <ControlBar />
    </>
  );
};

export default Business;
