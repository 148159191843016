import React, { FunctionComponent, useContext } from "react";
import { Context, ExplorerState } from "../Explorer/explorer";
import "./promopage.scss";
import promoPagesJson from "../../Data/promo_pages.json";
import { PromoPage } from "../../Types/types";

interface DefaultPromoPage extends PromoPage {
  imgSrc: string;
}

const PromoPageViewer: FunctionComponent = () => {
  const promoPages: PromoPage[] = promoPagesJson;

  const [state, setState]: [ExplorerState, CallableFunction] =
    useContext(Context);

  const enabledClass = state.type === "promo" ? " enabled" : "";

  const advance = () => {
    const currentSceneIdx = state.data.settings.navBar.panoSequence.findIndex(
      (p) => p.pano === state.spaceId
    );
    const nextScene =
      state.data.settings.navBar.panoSequence[currentSceneIdx + 1];

    setState({ ...state, spaceId: nextScene.pano, type: nextScene.type });
  };

  const promos = promoPages.map((p, index) => {
    const enabledClass =
      parseInt(state.spaceId) === index && state.type === "promo"
        ? " enabled"
        : "";
    if (p.template === "default") {
      return (
        <div key={`promo-${index}`} className={"promo-content" + enabledClass}>
          <img
            src={state.language[(p as DefaultPromoPage).imgSrc]}
            alt=""
          ></img>
        </div>
      );
    } else {
      const PromoPage =
        require(`./templates/${p.template}.template.tsx`).default;
      return (
        <PromoPage
          key={`promo-${index}`}
          advance={advance}
          enabledClass={enabledClass}
          promoData={p}
        />
      );
    }
  });

  return <div className={"promo-page" + enabledClass}>{promos}</div>;
};

export default PromoPageViewer;
