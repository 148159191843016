import React, { FunctionComponent, useContext, useEffect, useRef } from "react";
import { InfoHotspot } from "../../Types/hotspots.types";
import { ExplorerState, Context } from "../Explorer/explorer";
import Marzipano from "../Marzipano/marzipano";
import "./infoHotspot.scss";
import configsJson from "../../configs.json";
import { Configs, Guideline } from "../../Types/types";
import GA from "../../GA/ga";
import enJson from "../../Data/en.json";

interface InfoHotspotProps {
  scene: string;
  id: string;
  data: InfoHotspot;
  sfxPlay: CallableFunction;
  sfxPause: CallableFunction;
}

const YAW_OFFSET = 0.05;
const FLOAT_PRECISION = 0.001;

const InfoHotspotElement: FunctionComponent<InfoHotspotProps> = ({
  id,
  data,
  scene,
  sfxPlay,
}) => {
  const hotspotRef = useRef<HTMLDivElement>(null);

  const [state, setState]: [ExplorerState, CallableFunction] =
    useContext(Context);

  const configs: Configs = configsJson;

  useEffect(() => {
    if (hotspotRef.current) {
      Marzipano.loadHotspot(scene, hotspotRef.current, data);
    }

    return () => {
      Marzipano.removeHotspot();
    };
  }, [data, scene]);

  const activate = () => {
    const tmp = state.hotspots.map((h) => {
      if (h.id === id) {
        let hTmp = h;
        hTmp.active = true;
        hTmp.hover = false;
        return hTmp;
      } else {
        return h;
      }
    });

    const { yaw, pitch } = Marzipano.cameraPosition();
    if (
      Math.abs(yaw - (data.yaw + YAW_OFFSET)) < FLOAT_PRECISION &&
      Math.abs(pitch - data.pitch) < FLOAT_PRECISION
    ) {
      sfxPlay();
      setState({ ...state, hotspots: tmp });
    } else {
      Marzipano.lookTo(data.yaw + YAW_OFFSET, data.pitch, 1000, () => {
        sfxPlay();
        setState({ ...state, hotspots: tmp });
      });
    }

    const langPrefix = state.language.languageShort.toUpperCase();
    for (const [key, value] of Object.entries(enJson)) {
      if (key === data.title) {
        GA.sendEvent(
          `${langPrefix}_Open_Info_Hotspot_${
            data.tag ? data.tag : value.replaceAll(" ", "_")
          }`,
          {}
        );
      }
    }
  };

  const onMouseHover = (hover: boolean) => {
    const tmp = state.hotspots.map((h) => {
      if (h.id === id) {
        let hTmp = h;
        hTmp.hover = hover;
        return hTmp;
      } else {
        return h;
      }
    });

    setState({ ...state, hotspots: tmp });
  };

  const activeClass = state.hotspots.find((h) => h.id === id)?.active
    ? " active"
    : "";

  const hoverClass = state.hotspots.find((h) => h.id === id)?.hover
    ? " hover"
    : "";

  const innerIcon = <img src={configs.logoIcon} alt="" />;

  const hoverLabel = (
    <div className="hover-label">{state.language[data.title]}</div>
  );

  let display = {};
  if (state.data.settings.advancedAutoplay?.enabled) {
    const guide: Guideline[] = require(`../../Data/${state.data.settings.advancedAutoplay.guidelines}`);
    const guideScene = guide.find((g) => g.id === scene);
    if (guideScene) {
      //const idx = id.split("-").at(-1);
      const idx = id.split("-").pop();
      const actionTarget = `info-hotspot-${idx}`;
      const action = guideScene.actions.find((a) => a.target === actionTarget);
      if (!action) {
        display = { opacity: 0 };
      }
    }
  }

  return (
    <>
      <div
        ref={hotspotRef}
        key={id}
        id={id}
        className="info-hotspot"
        style={{ ...display }}
      >
        <div
          className={"hotspot" + activeClass + hoverClass}
          onClick={() => activate()}
          onMouseOver={() => {
            !state.hotspots.find((h) => h.id === id)?.active &&
              onMouseHover(true);
          }}
          onMouseOut={() => {
            !state.hotspots.find((h) => h.id === id)?.active &&
              onMouseHover(false);
          }}
        >
          {innerIcon}
          {hoverLabel}
        </div>
        <div className={"preview" + activeClass}>
          <div className="title">{state.language[data.title]}</div>
          <div className="content">{state.language[data.text]}</div>
        </div>
      </div>
    </>
  );
};

export default InfoHotspotElement;
