import React, { FunctionComponent, useContext } from "react";
import { ReactComponent as MicOnSVG } from "../../../../../Icons/Business/mic.svg";
import { ReactComponent as VidOnSVG } from "../../../../../Icons/Business/videocam.svg";
import { ReactComponent as PresentationSVG } from "../../../../../Icons/Business/present_to_all.svg";
import { ReactComponent as AddSVG } from "../../../../../Icons/Business/person_add_alt.svg";
import { ReactComponent as CallEndSVG } from "../../../../../Icons/Business/call_end.svg";
import "./controlBar.scss";
import { Context, ExplorerState } from "../../../../Explorer/explorer";

const ControlBar: FunctionComponent = () => {
  const [state, setState]: [ExplorerState, CallableFunction] =
    useContext(Context);

  const endCall = () => {
    setState({ ...state, business: { ...state.business, active: false } });
  };

  const togglePresentation = () => {
    setState({
      ...state,
      business: {
        ...state.business,
        presentation: true,
      },
    });
  };

  return (
    <>
      <div className="business-presentation-indication"></div>
      <div className="business-control-bar">
        <div className="controller">
          <MicOnSVG />
        </div>
        <div className="controller">
          <VidOnSVG />
        </div>
        <div
          className="controller green"
          onClick={() => {
            togglePresentation();
          }}
        >
          <PresentationSVG />
        </div>
        <div className="controller">
          <AddSVG />
        </div>
        <div
          className="controller end"
          onClick={() => {
            endCall();
          }}
        >
          <CallEndSVG />
        </div>
      </div>
    </>
  );
};

export default ControlBar;
