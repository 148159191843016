import React, {
  FunctionComponent,
  RefObject,
  useContext,
  useEffect,
  useRef,
} from "react";
import { ExplorerState, Context } from "../Explorer/explorer";
import Marzipano from "./marzipano";
import "./viewer.scss";

const Viewer360: FunctionComponent = () => {
  const mobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const viewerRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const [state, setState]: [ExplorerState, CallableFunction] =
    useContext(Context);

  const resetFlag = useRef<boolean>(false);

  useEffect(() => {
    if (viewerRef.current) {
      Marzipano.config(viewerRef.current, state.circuits[0]);
    }
    state.circuits.forEach((c) => {
      c.scenes.forEach((scene) => {
        Marzipano.loadScene(scene);
      });
    });
    Marzipano.changeToScene(
      state.circuits[0].scenes[0].id,
      state.circuits[0].scenes[0]
    );
  }, [state.circuits]);

  useEffect(() => {
    if (state.type === "360") {
      const scene = state.data.scenes.find((s) => s.id === state.spaceId);
      scene &&
        Marzipano.changeToScene(state.spaceId, scene, () => {
          Marzipano.resetAllLayers(state.spaceId);
        });
    }
  }, [state.data, state.spaceId, state.type]);

  useEffect(() => {
    if (state.landingPage) {
      Marzipano.changeToScene(
        state.circuits[0].scenes[0].id,
        state.circuits[0].scenes[0]
      );
    }
  }, [state.landingPage, state.circuits]);

  const resetHotspots = () => {
    const tmp = state.hotspots.map((h) => {
      const hTmp = h;
      hTmp.active = false;
      hTmp.hover = false;
      return hTmp;
    });
    setState({ ...state, hotspots: tmp });
  };

  const checkIfAnyHotspotIsActive = () => {
    let flag = false;
    state.hotspots.forEach((h) => {
      if (h.active || h.hover) {
        flag = true;
        resetFlag.current = true;
      }
    });

    return flag;
  };

  const singleTapScreen = () => {
    if (!state.autoplay) {
      if (!resetFlag.current) {
        mobile &&
          setState({ ...state, hiddenInterface: !state.hiddenInterface });
      } else {
        resetFlag.current = false;
      }
    }
  };

  const mouseDown = () => {
    if (checkIfAnyHotspotIsActive()) resetHotspots();
  };

  return (
    <div
      ref={viewerRef}
      className="marzipano-mobile"
      onClick={() => singleTapScreen()}
      onTouchStart={() => mouseDown()}
      onMouseDown={() => mouseDown()}
      style={state.landingPage ? { opacity: 0 } : {}}
    ></div>
  );
};

export default Viewer360;
