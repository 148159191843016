import React, { FunctionComponent, useContext, useState } from "react";
import { Context, ExplorerState } from "../../../Explorer/explorer";
import { ReactComponent as InfoSVG } from "../../../../Icons/UI/preceram/info.svg";
import { ReactComponent as ShareSVG } from "../../../../Icons/UI/preceram/share.svg";
import { ReactComponent as WppSVG } from "../../../../Icons/UI/preceram/whatsapp.svg";
import { ReactComponent as CloseSVG } from "../../../../Icons/UI/preceram/close.svg";
import { ReactComponent as VolumeUpSVG } from "../../../../Icons/UI/preceram/volume_up.svg";
import { ReactComponent as SoundOffSVG } from "../../../../Icons/UI/preceram/sound_off.svg";
import { ReactComponent as BusinessSVG } from "../../../../Icons/UI/preceram/article.svg";
import "./toolbar.scss";
import Marzipano from "../../../Marzipano/marzipano";
import GA from "../../../../GA/ga";

const Toolbar: FunctionComponent = () => {
  const [state, setState]: [ExplorerState, CallableFunction] =
    useContext(Context);

  const [infoOpen, openInfo] = useState<boolean>(false);

  const scene = state.data.scenes.find((s) => state.spaceId === s.id);

  if (state.autoplay) {
    return <></>;
  }

  if (state.data.settings.advancedAutoplay?.enabled) {
    return <></>;
  }

  let infoTool;
  let infoContainer;
  const description = scene?.description;
  const infoClick = (open: boolean) => {
    openInfo(open);
    const langPrefix = state.language.languageShort.toUpperCase();
    GA.sendEvent(`${langPrefix}_Click_Info_Space`, {});
  };
  if (scene && description) {
    infoTool = (
      <InfoSVG
        className={infoOpen ? "active" : ""}
        onClick={() => infoClick(!infoOpen)}
      />
    );
    infoContainer = (
      <div
        className={
          infoOpen
            ? "preceram-info-container active"
            : "preceram-info-container"
        }
      >
        <div className="header">
          <InfoSVG className="white" />
          <div className="title">{state.language[scene.name]}</div>
          <CloseSVG className="grey" onClick={() => infoClick(false)} />
        </div>
        <div className="info">{state.language[description]}</div>
      </div>
    );
  }

  let shareTool;
  const shareClick = () => {
    alert("This feature is not available yet.");
    const space = state.spaceId;
    console.log(space);
    const circuitIdx = state.circuits.find(
      (c) => c.scenes.findIndex((s) => s.id === space) !== undefined
    );
    console.log(circuitIdx);
    const camera = Marzipano.cameraPosition();
    console.log(camera);
    const langPrefix = state.language.languageShort.toUpperCase();
    GA.sendEvent(`${langPrefix}_Click_Sharelink`, {});
  };
  if (state.data.settings.toolBar.shareLink) {
    shareTool = <ShareSVG onClick={() => shareClick()} />;
  }

  let wppTool;
  const wppClick = () => {
    alert("This feature is not available yet.");
    const langPrefix = state.language.languageShort.toUpperCase();
    GA.sendEvent(`${langPrefix}_Click_Whatsapp`, {});
  };
  if (state.data.settings.toolBar.whatsapp) {
    wppTool = <WppSVG onClick={() => wppClick()} />;
  }

  let soundTool;
  const soundClick = (on: boolean) => {
    setState({ ...state, sound: on });
    const langPrefix = state.language.languageShort.toUpperCase();
    GA.sendEvent(`${langPrefix}_Click_audio_${on ? "On" : "Off"}`, {});
  };
  if (
    state.data.scenes.find((s) => s.audio !== undefined) !== undefined ||
    state.type === "video" ||
    state.data.scenes.find((s) => s.resonanceAudio !== undefined)
  ) {
    if (state.sound) {
      soundTool = (
        <VolumeUpSVG
          onClick={() => {
            soundClick(false);
          }}
        />
      );
    } else {
      soundTool = <SoundOffSVG onClick={() => soundClick(true)} />;
    }
  }

  let businessTool;
  const businessClick = () => {
    const active = { active: true };
    setState({ ...state, business: { ...state.business, active } });
  };
  if (state.data.settings.toolBar.business) {
    businessTool = <BusinessSVG onClick={() => businessClick()} />;
  }

  return (
    <>
      <div className="preceram-toolbar-desktop">
        {infoTool}
        {shareTool}
        {wppTool}
        {soundTool}
        {businessTool}
      </div>
      {infoContainer}
    </>
  );
};

export default Toolbar;
