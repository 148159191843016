import * as THREE from "three";
import Marzipano from "../Marzipano/marzipano";
const ResonanceAudio = require("resonance-audio").ResonanceAudio;

export interface Position {
  x: number;
  y: number;
  z: number;
}

export interface RoomDimensions {
  width: number;
  height: number;
  depth: number;
}

export interface RoomMaterials {
  left: string;
  right: string;
  back: string;
  front: string;
  up: string;
  down: string;
}

export default class ResonanceAudioLoad {
  audioContext!: AudioContext;

  audioElement!: HTMLAudioElement;

  source!: any;

  resonanceAudioScene!: typeof ResonanceAudio;

  constructor(
    audioPos: Position,
    maxDistance: number,
    listenerPos: Position,
    audioSrc: string,
    roomDimensions: RoomDimensions,
    roomMaterials: RoomMaterials
  ) {
    // Create an AudioContext
    this.audioContext = new AudioContext();

    /// -----------------  Laugh audio
    // Create an AudioElement.
    this.audioElement = document.createElement("audio");
    // Load an audio file into the AudioElement.
    // this.audioElement.src = audioSrc;
    this.audioElement.src = window.location.href + audioSrc.slice(2);
    this.audioElement.loop = true;
    this.audioElement.autoplay = true;
    this.audioElement.volume = 0;
    this.audioElement.crossOrigin = "";

    if (this.audioContext.state === "running") {
      // Create a (first-order Ambisonic) Resonance Audio scene and pass it
      // the AudioContext.
      this.resonanceAudioScene = new ResonanceAudio(this.audioContext);

      // Connect the scene’s binaural output to stereo out.
      if (this.audioContext.destination.maxChannelCount > 2) {
        this.resonanceAudioScene.ambisonicOutput.connect(
          this.audioContext.destination
        );
      } else {
        this.resonanceAudioScene.output.connect(this.audioContext.destination);
      }

      // Add the room definition to the scene.
      this.resonanceAudioScene.setRoomProperties(roomDimensions, roomMaterials);

      // Generate a MediaElementSource from the AudioElement.
      let audioElementSource = this.audioContext.createMediaElementSource(
        this.audioElement
      );

      // Add the MediaElementSource to the scene as an audio input source.
      this.source = this.resonanceAudioScene.createSource();
      audioElementSource.connect(this.source.input);

      // Set the source position relative to the room center (source default position).
      this.source.setPosition(audioPos.x, audioPos.y, audioPos.z);
      //this.source.setMaxDistance(maxDistance);
      //this.source.setMinDistance(0.01);

      // Play the audio.
      //this.audioElement.play();

      //set listener position
      // const projection = Marzipano.getProjection();
      // const matrix = new THREE.Matrix4();
      // matrix.set(
      //   projection[0],
      //   projection[1],
      //   projection[2],
      //   projection[3],
      //   projection[4],
      //   projection[5],
      //   projection[6],
      //   projection[7],
      //   projection[8],
      //   projection[9],
      //   projection[10],
      //   projection[11],
      //   projection[12],
      //   projection[13],
      //   projection[14],
      //   projection[15]
      // );
      // this.resonanceAudioScene.setListenerFromMatrix(matrix);
      // this.resonanceAudioScene.setListenerPosition(
      //   listenerPos.x,
      //   listenerPos.y,
      //   listenerPos.z
      // );
      const { yaw, pitch } = Marzipano.cameraPosition();

      const forwardX = Math.cos(yaw) * Math.cos(pitch);
      const forwardY = Math.sin(yaw);
      const forwardZ = Math.sin(pitch);

      const upX = 0;
      const upY = 0;
      const upZ = 1;

      this.resonanceAudioScene.setListenerOrientation(
        forwardX,
        forwardY,
        forwardZ,
        upX,
        upY,
        upZ
      );
    }
  }

  play() {
    this.audioElement.play();
  }

  pause() {
    this.audioElement.pause();
  }

  destroy() {
    this.audioElement.pause();
    this.audioElement.src = "";
  }

  changeAudioPosition(newPosition: Position) {
    this.source.setPosition(newPosition.x, newPosition.y, newPosition.z);
  }

  changeListenerMatrix(matrix: THREE.Matrix4) {
    if (this.audioContext.state === "running") {
      this.resonanceAudioScene &&
        this.resonanceAudioScene.setListenerFromMatrix(matrix);
    }
  }

  changeListenerOrientation(
    forwardX: number,
    forwardY: number,
    forwardZ: number,
    upX: number,
    upY: number,
    upZ: number
  ) {
    if (this.audioContext.state === "running") {
      this.resonanceAudioScene &&
        this.resonanceAudioScene.setListenerOrientation(
          forwardX,
          forwardY,
          forwardZ,
          upX,
          upY,
          upZ
        );
    }
  }

  mute() {
    this.audioElement.muted = true;
  }

  unmute() {
    this.audioElement.muted = false;
  }

  changeVolume(on: boolean) {
    if (on) {
      const interval = setInterval(() => {
        if (this.audioElement.volume < 1.0) {
          this.audioElement.volume = Math.min(
            this.audioElement.volume + 0.1,
            1.0
          );
        } else {
          clearInterval(interval);
          this.play();
        }
      }, 100);
    } else {
      const interval = setInterval(() => {
        if (this.audioElement.volume > 0.0) {
          this.audioElement.volume = Math.max(
            this.audioElement.volume - 0.1,
            0.0
          );
        } else {
          clearInterval(interval);
        }
      }, 100);
    }
  }
}
