import React, { FunctionComponent, useContext } from "react";
import { ReactComponent as ArrowBackSVG } from "../../../../Icons/UI/preceram/arrow_back.svg";
import { ReactComponent as ArrowForwardSVG } from "../../../../Icons/UI/preceram/arrow_forward.svg";
import { ReactComponent as PlaySVG } from "../../../../Icons/UI/preceram/play_circle.svg";
import { ReactComponent as PauseSVG } from "../../../../Icons/UI/preceram/pause_circle.svg";
import { Configs, PromoPage, TranslationObject } from "../../../../Types/types";
import configsJson from "../../../../configs.json";
import promoPagesJson from "../../../../Data/promo_pages.json";
import { ExplorerState, Context } from "../../../Explorer/explorer";
import "./navArrows.scss";

const NavArrows: FunctionComponent = () => {
  const [state, setState]: [ExplorerState, CallableFunction] =
    useContext(Context);

  const configs: Configs = configsJson;

  const promoPages: PromoPage[] = promoPagesJson;

  const translations: TranslationObject = require(`../../../../Language/${state.language.languageShort}.json`);

  const toggleAutoplay = (on: boolean) => {
    setState({ ...state, autoplay: on });
  };

  let currentSceneIndex = state.data.scenes.findIndex(
    (scene) => scene.id === state.spaceId
  );
  let nScenes = state.data.scenes.length;

  if (state.data.settings.navBar.panoSequence.length > 0) {
    currentSceneIndex = state.data.settings.navBar.panoSequence.findIndex(
      (p) => p.pano === state.spaceId
    );
    nScenes = state.data.settings.navBar.panoSequence.length;
  }

  const backClass = currentSceneIndex === 0 ? " inactive" : "";
  let forwardClass = currentSceneIndex === nScenes - 1 ? " inactive" : "";

  if (configs.contactpage.enabled) {
    forwardClass = "";
  }

  const forwardClick = () => {
    if (currentSceneIndex < nScenes - 1) {
      let nextSceneId = state.data.scenes[currentSceneIndex + 1]?.id;
      let type = "360";
      if (state.data.settings.navBar.panoSequence.length > 0) {
        const next =
          state.data.settings.navBar.panoSequence[currentSceneIndex + 1];
        nextSceneId = next.pano;
        type = next.type;
      }

      setState({ ...state, spaceId: nextSceneId, type: type });
    } else {
      if (configs.contactpage.enabled) {
        setState({ ...state, contactPage: true });
      }
    }
  };

  const backClick = () => {
    if (currentSceneIndex > 0) {
      let nextSceneId = state.data.scenes[currentSceneIndex - 1]?.id;
      let type = "360";
      if (state.data.settings.navBar.panoSequence.length > 0) {
        const next =
          state.data.settings.navBar.panoSequence[currentSceneIndex - 1];
        nextSceneId = next.pano;
        type = next.type;
      }

      setState({ ...state, spaceId: nextSceneId, type: type });
    }
  };

  let nextSceneName;
  if (currentSceneIndex < nScenes - 1) {
    if (state.data.settings.navBar.panoSequence.length > 0) {
      const next =
        state.data.settings.navBar.panoSequence[currentSceneIndex + 1];
      const nextSceneId = next.pano;
      const type = next.type;

      if (type !== "360") {
        nextSceneName = nextSceneId;
        if (type === "promo") {
          const promo = promoPages[parseInt(nextSceneId)];
          nextSceneName = promo ? state.language[promo.name] : "";
        }
        if (type === "video") {
          nextSceneName = next.label
            ? state.language[next.label]
            : translations.videoNavLabel;
        }
      } else {
        nextSceneName = state.data.scenes.find(
          (s) => s.id === nextSceneId
        )?.name;
        nextSceneName = nextSceneName
          ? state.language[nextSceneName]
          : nextSceneName;
      }
    } else {
      if (currentSceneIndex > 0)
        nextSceneName = state.data.scenes[currentSceneIndex - 1].name;
    }
  } else {
    if (configs.contactpage.enabled) {
      nextSceneName = translations.contactUsLabel;
    }
  }

  let prevSceneName;
  if (currentSceneIndex > 0) {
    if (state.data.settings.navBar.panoSequence.length > 0) {
      const next =
        state.data.settings.navBar.panoSequence[currentSceneIndex - 1];
      const nextSceneId = next.pano;
      const type = next.type;

      if (type !== "360") {
        prevSceneName = nextSceneId;
        if (type === "promo") {
          const promo = promoPages[parseInt(nextSceneId)];
          prevSceneName = promo ? state.language[promo.name] : "";
        }
        if (type === "video") {
          prevSceneName = next.label
            ? state.language[next.label]
            : translations.videoNavLabel;
        }
      } else {
        prevSceneName = state.data.scenes.find(
          (s) => s.id === nextSceneId
        )?.name;
        prevSceneName = prevSceneName
          ? state.language[prevSceneName]
          : prevSceneName;
      }
    } else {
      prevSceneName = state.data.scenes[currentSceneIndex - 1].name;
    }
  }

  const autoplayClass =
    state.type === "video" ? "autoplay disabled" : "autoplay disabled";

  if (state.autoplay || !state.data.settings.navBar.enabled) {
    return (
      <div className="preceram-arrows-desktop">
        <PauseSVG
          className={autoplayClass}
          onClick={() => {
            toggleAutoplay(false);
          }}
        />
      </div>
    );
  }

  if (state.data.settings.advancedAutoplay?.enabled) {
    return <></>;
  }

  return (
    <div className="preceram-arrows-desktop">
      <div className={"preceram-arrow back" + backClass}>
        <ArrowBackSVG className={backClass} onClick={() => backClick()} />
        <div className="next-scene">{prevSceneName}</div>
      </div>
      <PlaySVG
        className={autoplayClass}
        onClick={() => {
          toggleAutoplay(true);
        }}
      />
      <div className={"preceram-arrow forward" + forwardClass}>
        <ArrowForwardSVG
          className={forwardClass}
          onClick={() => forwardClick()}
        />
        <div className="next-scene">{nextSceneName}</div>
      </div>
    </div>
  );
};

export default NavArrows;
